<template>
    <div>
        <el-card class="box-card" shadow="hover" v-if="!variantCardShow">
            <el-card class="search-card" shadow="hover">
                <div class="search-header">
                    <div class="search-title">
                        <i class="el-icon-goods"></i>
                        <span>Product Management</span>
                    </div>
                    <el-button type="primary" @click="handleAdd" class="add-button">
                        <i class="el-icon-plus"></i>
                        Add Product
                    </el-button>
                </div>
                <el-form :inline="true" :model="formInline" class="search-form">
                    <el-form-item>
                        <el-input 
                            v-model="formInline.search" 
                            @change="onSubmit"
                            @keyup.enter.native="onSubmit"
                            placeholder="Search products..."
                            prefix-icon="el-icon-search"
                            clearable>
                        </el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button 
                            @click="onSubmit" 
                            type="info">
                            <i class="el-icon-search"></i>
                            Search
                        </el-button>
                    </el-form-item>
                </el-form>
            </el-card>

            <el-table 
                :data="tableData" 
                style="width: 100%" 
                header-cell-class-name="table_header"
                v-loading="tableLoading" 
                stripe
                border
                class="product-table">
                <el-table-column prop="id" label="id" align="center" width="50px" sortable>
                </el-table-column>
                
                <el-table-column prop="active" label="Active" align="center" sortable>
                    <template v-slot="{ row }">
                        <el-switch v-model="row.active" disabled></el-switch>
                    </template>
                </el-table-column>
                <el-table-column prop="valid" label="Valid" align="center" sortable>
                    <template v-slot="{ row }">
                        <el-switch v-model="row.valid" disabled></el-switch>
                    </template>
                </el-table-column>

                <el-table-column prop="product_name" label="product" align="center" width="250px" sortable>
                    <template v-slot="{ row }">
                        <el-tooltip class="item" effect="dark" :content="row.product_name" placement="top">
                            <el-tag disable-transitions type="success">
                                {{ row.product_name }}
                            </el-tag>
                        </el-tooltip>

                    </template>

                </el-table-column>

                <el-table-column label="Prod. Type" align="center" width="200px" sortable>
                    <template v-slot="{ row }">
                        <el-tag v-for="collection in row.product_collections" :key="collection.id" disable-transitions>
                            {{ collection.collection_name }}
                        </el-tag>
                    </template>
                </el-table-column>

                <el-table-column prop="point" label="Prod. Tag" align="center" width="200px">
                    <template v-slot="{ row }">
                        <el-tag v-for="tag in row.product_tags" :key="tag.id" disable-transitions effect="plain"
                            type="dark">
                            {{ tag.tag_name }}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="code" label="Option" align="center">
                    <template v-slot="{ row }">
                        <span>{{ row.variant_options.map(item => item.option_name).join(' ; ') }}</span>
                    </template>
                </el-table-column>
                

                <el-table-column label="Stocks" align="center">
                    <template v-slot="{ row }">
                        <span>{{ row.variants.map(item => item.variant_stock).join(' ; ') }}</span>
                    </template>
                </el-table-column>

                <el-table-column label="Server group" align="center">
                    <template v-slot="{ row }">
                        <span>{{ [...new Set(row.variants.map(item => item.server_group.name))].join(' ; ') }}</span>
                    </template>
                </el-table-column>

                <!-- <el-table-column label="Acl group" align="center">
              <template v-slot="{ row }">
                  <span>{{ [...new Set(row.variants.map(item => item.acl_group.name))].join(' ; ') }}</span>
              </template>
          </el-table-column> -->


                <el-table-column prop="created_at" label="Create Time" align="center" width="120">
                    <template v-slot="{ row }">
                        <el-tooltip class="item" effect="dark" :content="row.created_at" placement="top-start"
                            :content-style="{ 'max-height': '60px' }">
                            <div class="ellipsis">{{ row.created_at.substr(0, 10) }}</div>
                        </el-tooltip>
                    </template>
                </el-table-column>

                <el-table-column label="Action" align="center" width="130" fixed>
                    <template v-slot="{ row, $index }">
                        <el-button-group>
                            <el-button 
                                type="primary" 
                                size="small" 
                                @click="handleProductEdit(row, $index)"
                                icon="el-icon-edit">
                                Edit
                            </el-button>
                            <el-button 
                                type="danger" 
                                size="small" 
                                @click="handleDelete(row, $index)"
                                icon="el-icon-delete">
                                Delete
                            </el-button>
                        </el-button-group>
                    </template>
                </el-table-column>
                <el-table-column prop="old_flag" label="Old" align="center" sortable>
                    <template v-slot="{ row }">
                        <el-switch v-model="row.old_flag" disabled></el-switch>
                    </template>
                </el-table-column>

            </el-table>
            <el-pagination background layout="->,prev, pager, next" :total="total" style="margin-top: 20px;"
                :page-size="10" :current-page.sync="currentPage" @current-change="handleCurrentChange">
            </el-pagination>
        </el-card>
        <el-card v-else v-loading="loading" class="edit-card" shadow="hover">
            <div class="page-header-wrapper">
                <el-page-header @back="goBack" content="Edit Product" class="custom-page-header">
                    <template slot="title">
                        <span class="back-text"><i class="el-icon-arrow-left"></i> Back</span>
                    </template>
                </el-page-header>
            </div>
            
            <div class="edit-content">
                <div v-if="!isEdit" class="product-form">
                    <el-card shadow="hover" class="section-card">
                        <div slot="header" class="card-header">
                            <i class="el-icon-shopping-cart-2"></i>
                            <span>Product Information</span>
                        </div>
                        <el-form label-position="top" label-width="80px" class="product-info-form">
                            <el-form-item label="Select the Shopify product" class="shopify-select">
                                <el-select 
                                    v-model="shopifyValue" 
                                    @change="handleSelectChange"
                                    placeholder="Select a product"
                                    filterable>
                                    <el-option 
                                        v-for="item in optionsList" 
                                        :key="item.shopify_product_id"
                                        :value="item.shopify_product_id" 
                                        :label="item.product_name">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            
                            <el-form-item label="Product Name">
                                <el-input v-model="product_name" placeholder="Enter product name"></el-input>
                            </el-form-item>

                            <el-form-item label="Product Groups">
                                <div class="tags-container">
                                    <el-tag 
                                        v-for="collection in product_collections"
                                        :key="collection.id"
                                        type="primary"
                                        class="product-tag">
                                        {{ collection.collection_name }}
                                    </el-tag>
                                </div>
                            </el-form-item>

                            <el-form-item label="Product Tags">
                                <div class="tags-container">
                                    <el-tag 
                                        v-for="tag in product_tags"
                                        :key="tag.id"
                                        type="info"
                                        class="product-tag">
                                        {{ tag.tag_name }}
                                    </el-tag>
                                </div>
                            </el-form-item>
                        </el-form>
                    </el-card>

                    <el-card shadow="hover" class="section-card">
                        <div slot="header" class="card-header">
                            <i class="el-icon-document"></i>
                            <span>Product Description</span>
                        </div>
                        <div class="description-container">
                            <div ref="div" class="description-content"></div>
                        </div>
                    </el-card>
                </div>

                <div class="variants-section">
                    <el-card shadow="hover" class="section-card">
                        <div slot="header" class="card-header">
                            <i class="el-icon-goods"></i>
                            <span>Product Variants</span>
                        </div>
                        <el-table 
                            :data="variants" 
                            style="width: 100%" 
                            height="500" 
                            border
                            class="variants-table">
                            <el-table-column label="Variant Id" prop="id" align="center">
                            </el-table-column>
                            <el-table-column label="Options Name" prop="variant_name" align="center">
                            </el-table-column>
                            <el-table-column label="Price" prop="variant_price" align="center">
                            </el-table-column>
                            <el-table-column label="Stock" prop="variant_stock" align="center">
                            </el-table-column>
                            <el-table-column label="Server Group" prop="variant_stock" align="center">
                                <template v-slot="{ row }">
                                    <span>{{ row.server_group.name }}</span>
                                </template>
                            </el-table-column>
                            <!-- <el-table-column label="Acl Group" prop="variant_stock" align="center">
                      <template v-slot="{ row }">
                          <span>{{ row.acl_group.name }}</span>
                      </template>
                  </el-table-column> -->
                            <el-table-column label="Location" prop="variant_desc" align="center">
                            </el-table-column>
                            <el-table-column label="Cart Step" prop="cart_step" align="center">
                            </el-table-column>
                            <el-table-column label="Proxy time" prop="proxy_time" align="center">
                            </el-table-column>
                            <el-table-column prop="variant_stock" label="Active" align="center">
                                <template v-slot="{ row }">
                                    <el-tag type="success" v-if="row.is_active">Yes</el-tag>
                                    <el-tag type="danger" v-else>No</el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column label="Actions" align="center">
                                <template v-slot="{ row, $index }">
                                    <el-button type="text" @click="hanldeEdit(row, $index)">Edit</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-card>
                </div>
            </div>

            <div class="form-actions">
                <el-button 
                    type="primary" 
                    :disabled="isButtonDisabled" 
                    @click="handleSubmitAdd" 
                    :loading="formLoading"
                    icon="el-icon-check">
                    Submit
                </el-button>
            </div>
        </el-card>
        <el-dialog title="Modify product" :visible.sync="dialogFormVisible" :close-on-click-modal="false">
            <el-form :model="form" v-loading="formLoading" ref="formform">
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="options" :label-width="formLabelWidth">
                            {{ form.variant_name }}
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="Product group" :label-width="formLabelWidth">
                            {{ form.product_collections }}
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="price" :label-width="formLabelWidth">
                            <div v-if="!isEdit">{{ form.variant_price }}</div>
                            <el-input v-model="form.variant_price" v-else></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="Product Tag" :label-width="formLabelWidth">
                            {{ form.product_tags }}
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item label="serve group" :label-width="formLabelWidth">
                    <el-select v-model="form.serveId">
                        <el-option label="区域一" v-for="item_s in serverList" :label="item_s.name" :value="item_s.id"
                            :key="`serve-${item_s.id}`"></el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item label="acl group" :label-width="formLabelWidth">
              <el-select v-model="form.aclId">
                  <el-option v-for="item in aclList" :label="item.name" :value="item.id"
                             :key="`acl-${item.id}`"></el-option>
              </el-select>
          </el-form-item> -->
                <el-form-item label="active" :label-width="formLabelWidth">
                    <el-select v-model="form.is_active">
                        <el-option label="Yes" :value="true"></el-option>
                        <el-option label="No" :value="false"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="cart step" :label-width="formLabelWidth">
                    <el-select v-model="form.cart_step" :disabled="isEdit">
                        <!-- 1 8 16 32 64 128 256 -->
                        <el-option label="1" :value="1"></el-option>
                        <el-option label="8" :value="8"></el-option>
                        <el-option label="16" :value="16"></el-option>
                        <el-option label="32" :value="32"></el-option>
                        <el-option label="64" :value="64"></el-option>
                        <el-option label="128" :value="128"></el-option>
                        <el-option label="255" :value="256"></el-option>
                        <el-option label="256" :value="256"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="Proxy Time" :label-width="formLabelWidth">
                    <el-input-number v-model="form.proxy_time" :min="1"></el-input-number>
                </el-form-item>
                <el-form-item label="Stock" :label-width="formLabelWidth">
                    <el-input v-model="form.variant_stock" autocomplete="off" disabled></el-input>
                </el-form-item>
                <el-form-item label="Location" :label-width="formLabelWidth">
                    <el-input v-model="form.variant_desc" autocomplete="off" type="textarea"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">cancel</el-button>
                <el-button type="primary" @click="handleSubmit" :disabled="formLoading">confirm</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { getList, handle, add, get_product_from_shopify, edit } from "@/api/dashboard/productManager"
// import { getList as aclGetList } from "@/api/dashboard/acl"
import { getList as serversGetList } from "@/api/dashboard/servergroup"
import _ from "lodash"

export default {
    data() {
        return {
            formInline: {
                search: '',
            },
            tableData: [],
            currentPage: 1,
            total: 0,
            tableLoading: false,
            dialogFormVisible: false,
            EditLoading: false,
            variantCardShow: false,
            shopifyValue: '',
            loading: false,
            optionsList: [],
            desc: "",
            product_collections: [],
            product_name: '',
            product_tags: [],
            variant_options: [],
            variants: [],
            isLoading: false,
            form: {
                variant_name: '',
                variant_price: '',
                product_collections: '',
                product_tags: '',
                serveId: "",
                aclId: "",
                is_active: '',
                cart_step: '',
                variant_stock: '',
                variant_desc: '',
                proxy_time: 1,
            },
            formLabelWidth: '120px',
            serverList: [],
            formLoading: false,
            isEdit: false,
            isButtonDisabled: false,
        }
    },
    mounted() {
        new this.$wow.WOW().init()
        this.getTableData()
        this.isButtonDisabled = false
    },
    methods: {
        tableRowClassName({ row }) {
            console.log('Row active status:', row.active);
            return row.active ? '' : 'row-red';
        },
        handlePassword() {
            if (this.password == "qazmkoiuytr") this.password = ""
        },

        onSubmit() {
            this.currentPage = 1
            this.getTableData()
        },
        handleCurrentChange(val) {
            this.getTableData()
        },
        getTableData() {
            const offset = (this.currentPage - 1) * 10
            const param = { search: this.formInline.search, limit: 10, offset }
            this.tableLoading = true
            getList(param, (res) => {


                this.tableData = res.data.data
                // this.tableData = aa.data.data
                this.total = res.data.total
                // this.total = aa.data.total
                this.tableLoading = false
            })
            serversGetList({ limit: 10000, offset: 0 }, res => {
                this.serverList = res.data.data
            }, res => {
                this.$message.error(res.data.msg)
            })
        },
        handleDelete(row, index) {
            this.$confirm(`Confirm deletion`, 'Delete ?', {
                confirmButtonText: 'YES',
                cancelButtonText: 'NO',
                type: 'warning'
            }).then(() => {
                handle(
                    "delete",
                    { id: row.id },
                    (res) => {
                        this.currentPage = this.currentPage === 1 ? this.currentPage : (this.tableData.length == 1 && index === 0) ? this.currentPage - 1 : this.currentPage
                        this.$message.success("successfully")
                        this.getTableData()
                    },
                )
            }).catch(() => {

            })
        },

        async handleAdd() {
            this.variantCardShow = true
            this.isEdit = false
            this.loading = true
            const p1 = new Promise((resolve, reject) => {
                get_product_from_shopify((res) => {
                    this.optionsList = res.data
                    resolve()
                }, res => {
                    reject()

                })
            })
            const p2 = new Promise((resolve, reject) => {
                serversGetList({ limit: 10000, offset: 0 }, res => {
                    this.serverList = res.data.data
                    resolve()
                }, res => {
                    reject()
                })
            })
            const result = await Promise.allSettled([p1, p2])

            const flag = result.every(item => item.status == "fulfilled")
            if (flag) {
                this.loading = false
            } else {
                this.$message.warning("Failed to obtain data, please try again")
                this.goBack()
            }
        },
        goBack() {
            this.variantCardShow = false
            this.form = {
                variant_name: '',
                variant_price: '',
                product_collections: '',
                product_tags: '',
                serveId: "",
                aclId: "",
                is_active: '',
                cart_step: '',
                variant_stock: '',
                variant_desc: '',
                proxy_time: 1,
            }
            this.variants = []
            this.product_tags = []
            this.product_collections = []
            this.shopifyValue = ""

            this.getTableData()
            this.isButtonDisabled = false
        },
        handleSelectChange(val, abc) {
            const data = this.optionsList.find(item => item.shopify_product_id == val)
            this.$refs.div.innerHTML = data.product_desc ? data.product_desc.replaceAll('\n', '') : "<div>There is no Product description</div>"
            this.product_collections = data.product_collections
            this.product_name = data.product_name
            this.product_desc = data.product_desc
            this.product_tags = data.product_tags
            this.variant_options = data.variant_options
            this.variants = abc || data.variants
        },
        hanldeEdit(data, index) {
            this.dialogFormVisible = true
            this.form.id = parseInt(data.id)
            this.form.variant_name = data.variant_name
            this.form.variant_price = data.variant_price
            this.form.product_collections = this.product_collections.map(item => item.collection_name).join(' ; ')
            this.form.product_tags = this.product_tags.map(item => item.tag_name).join(' ; ')
            this.form.is_active = data.is_active
            this.form.cart_step = data.cart_step
            this.form.variant_stock = data.variant_stock
            this.form.variant_desc = data.variant_desc
            this.form.proxy_time = data.proxy_time
            this.index = index
            this.form.serveId = data.server_group ? data.server_group.id : ""
        },
        handleSubmit() {
            const {
                serveId,
                // aclId,
                is_active,
                cart_step,
                variant_stock,
                variant_desc,
                proxy_time,
                variant_price
            } = this.form
            this.variants[this.index].server_group.name = this.serverList.find(r => r.id == serveId)?.name
            this.variants[this.index].server_group.id = serveId
            // this.variants[this.index].acl_group.name = this.aclList.find(r => r.id == aclId)?.name
            // this.variants[this.index].acl_group.id = aclId
            this.variants[this.index].is_active = is_active
            this.variants[this.index].cart_step = cart_step
            this.variants[this.index].proxy_time = proxy_time
            this.variants[this.index].variant_stock = variant_stock
            this.variants[this.index].variant_desc = variant_desc
            this.isEdit && (this.variants[this.index].variant_price = variant_price)
            this.$refs.formform.resetFields()
            this.dialogFormVisible = false
        },
        handleSubmitAdd() {
            this.isButtonDisabled = true;  // 禁用按钮，防止重复点击
            this.formLoading = true;  // 显示加载动画

            const variants = _.cloneDeep(this.variants);
            variants.forEach(item => {
                item.server_group = item.server_group.id;
                // item.acl_group = item.acl_group.id;
            });

            const param = {
                shopify_product_id: this.shopifyValue,
                variants,
                variant_options: this.variant_options,
                product_collections: this.product_collections,
                product_tags: this.product_tags,
                product_name: this.product_name,
                product_desc: this.product_desc
            };
            this.$message.success("请求中，请稍后...");
            if (this.isEdit) {
                const editParam = {
                    id: this.editId,
                    product_tags: this.product_tags,
                    product_name: this.product_name,
                    product_desc: this.product_desc,
                    shopify_product_id: this.shopifyValue,
                    variants,
                };
                edit(editParam, res => {
                    this.formLoading = false;  // 停止加载动画
                    this.isButtonDisabled = false;  // 启用按钮
                    this.$message.success("编辑成功！");  // 显示成功提示
                }, res => {
                    this.formLoading = false;  // 停止加载动画
                    this.isButtonDisabled = false;  // 启用按钮
                    this.$message.warning(res.msg);  // 显示失败提示
                });
            } else {
                add(param, res => {
                    this.formLoading = false;  // 停止加载动画
                    this.isButtonDisabled = false;  // 启用按钮
                    this.$message.success("添加成功！");  // 显示成功提示
                }, res => {
                    this.formLoading = false;  // 停止加载动画
                    this.isButtonDisabled = false;  // 启用按钮
                    this.$message.warning(res.msg);  // 显示失败提示
                });
            }
        },
        handleEditSelectChange(row_data) {

            this.product_collections = row_data.product_collections
            this.product_name = row_data.product_name
            this.product_desc = row_data.product_desc
            this.product_tags = row_data.product_tags
            this.variant_options = row_data.variant_options
            this.variants = row_data.variants
        },
        async handleProductEdit(row, index) {
            this.isEdit = true
            this.editId = row.id
            this.shopifyValue = +row.shopify_product_id
            this.variantCardShow = true
            this.handleEditSelectChange(row)


        }


    }
}
</script>

<style lang="scss" scoped>
.box-card {
    margin-bottom: 20px;
}

.search-card {
    margin-bottom: 15px;
    background: linear-gradient(135deg, #ff758c 0%, #ff7eb3 100%) !important;
    
    .search-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;
        padding: 0 12px;
        
        .search-title {
            font-size: 16px;
            font-weight: 600;
            display: flex;
            align-items: center;
            color: #303133;
            
            i {
                margin-right: 6px;
                font-size: 18px;
            }
        }
        
        .add-button {
            background: linear-gradient(135deg, #409EFF 0%, #66b1ff 100%);
            border: none;
            padding: 8px 15px;
            
            i {
                margin-right: 5px;
            }
            
            &:hover {
                background: linear-gradient(135deg, #66b1ff 0%, #409EFF 100%);
                transform: translateY(-2px);
            }
        }
    }
    
    .search-form {
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 0 12px;
        
        .el-input {
            width: 250px;
        }
        
        .el-button {
            background: linear-gradient(135deg, #909399 0%, #C0C4CC 100%);
            border: none;
            padding: 9px 15px;
            
            &:hover {
                background: linear-gradient(135deg, #C0C4CC 0%, #909399 100%);
                transform: translateY(-2px);
            }
            
            i {
                margin-right: 5px;
            }
        }
    }
}

.product-table {
    margin-top: 20px;
    
    :deep(.table_header) {
        background: linear-gradient(135deg, #f5f7fa 0%, #e4e7ed 100%);
        color: #303133;
        font-weight: 600;
        padding: 12px 0;
    }
    
    :deep(.el-table__row) {
        transition: all 0.3s;
        
        &:hover {
            background-color: #f5f7fa !important;
            transform: translateY(-2px);
        }
    }
    
    :deep(.el-button-group) {
        .el-button {
            margin: 0 5px;
            padding: 8px 15px;
            
            &:hover {
                transform: translateY(-2px);
            }
        }
    }
    
    :deep(.el-tag) {
        margin: 2px;
        transition: all 0.3s;
        
        &:hover {
            transform: translateY(-2px);
        }
    }
}

.page-header-wrapper {
    background: linear-gradient(135deg, #f5f7fa 0%, #e4e7ed 100%);
    padding: 16px 24px;
    border-radius: 8px;
    margin-bottom: 20px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05);

    .custom-page-header {
        background: transparent;
        padding: 0;
        
        :deep(.el-page-header__left) {
            .el-icon-back {
                display: none;
            }
            
            .el-page-header__title {
                .back-text {
                    font-size: 14px;
                    color: #409EFF;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    transition: all 0.3s;
                    
                    &:hover {
                        color: #66b1ff;
                        transform: translateX(-3px);
                    }
                    
                    i {
                        margin-right: 5px;
                    }
                }
            }
        }
        
        :deep(.el-page-header__content) {
            font-size: 18px;
            font-weight: 600;
            color: #303133;
        }
    }
}

.edit-content {
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
}

.section-card {
    margin-bottom: 20px;
    transition: all 0.3s;
    background: #fff;
    border-radius: 8px;
    
    &:hover {
        transform: translateY(-5px);
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    }
    
    .card-header {
        display: flex;
        align-items: center;
        font-weight: bold;
        padding: 15px 20px;
        border-bottom: 1px solid #ebeef5;
        
        i {
            margin-right: 8px;
            color: #409EFF;
            font-size: 18px;
        }
    }
    
    .el-card__body {
        padding: 20px;
    }
}

.product-info-form {
    .shopify-select {
        .el-select {
            width: 100%;
        }
    }
    
    .tags-container {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        margin-top: 10px;
        
        .product-tag {
            margin: 0;
            transition: all 0.3s;
            
            &:hover {
                transform: translateY(-2px);
            }
        }
    }
}

.description-container {
    background: #f5f7fa;
    border-radius: 4px;
    padding: 15px;
    min-height: 200px;
    margin-top: 15px;
    
    .description-content {
        white-space: pre-wrap;
        color: #606266;
        font-size: 14px;
        line-height: 1.6;
    }
}

.variants-table {
    margin-top: 15px;
    
    :deep(.el-table) {
        border-radius: 8px;
        overflow: hidden;
        
        th {
            background: linear-gradient(135deg, #f5f7fa 0%, #e4e7ed 100%);
            color: #303133;
            font-weight: 600;
        }
        
        td {
            padding: 12px 0;
        }
    }
}

.form-actions {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    gap: 15px;
    
    .el-button {
        padding: 12px 30px;
        font-size: 16px;
        transition: all 0.3s;
        
        &:hover {
            transform: translateY(-2px);
        }
    }
}
</style>