<template>
    <div>
        <el-card class="box-card">
             <el-card style="background-color: #febac3;margin-bottom: 20px;">
                <el-form :inline="true" :model="formInline" class="demo-form-inline">
                    <el-form-item>
                        <el-input @change="onSubmit" v-model="formInline.search"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button @click="onSubmit">search</el-button>
                    </el-form-item>
                </el-form>
            </el-card>
            <el-table :data="tableData" header-cell-class-name="table_header" style="width: 100%"
                      v-loading="tableLoading">
                <el-table-column align="center" label="id" prop="id"></el-table-column>
                <!-- <el-table-column align="center" label="id count" prop="id_count"></el-table-column> -->
                <el-table-column align="center" label="cidr" prop="cidr"></el-table-column>
                
                <el-table-column align="center" label="Exclude Acls" show-overflow-tooltip>
                    <template v-slot="{ row }">
                        <template>
                            <ul class="acl-list">
                                <li v-for="item in row.exclude_acl" :key="item.id">
                                    <el-tooltip :content="item.name" placement="top">
                                        <el-tag  type="info" class="truncate-tag" >{{ item.name }}</el-tag>
                                    </el-tooltip>
                                </li>
                            </ul>
                        </template>
                    </template>
                </el-table-column>
                
                <el-table-column align="center" label="Action" width="200">
                    <template v-slot="{ row }">
                        <el-button @click="handleEdit(row)" type="text">Edit</el-button>
                        <!-- <el-button @click="handleDelete(row, $index)" type="text">Delete</el-button> -->
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination :current-page.sync="currentPage" :total="total" @current-change="handleCurrentChange"
                           layout="->,prev, pager, next" style="margin-top: 20px;"></el-pagination>
        </el-card>
        <el-dialog :visible.sync="dialogFormVisible" width="35%">
            <div style="width: 75%;margin: 0 auto;">
                <el-form :model="form" :rules="rules" label-width="120px" ref="ruleForm">
                    <el-form-item label="cidr" prop="cidr" >
                        <el-input v-model="form.cidr" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="ip count" prop="ip_count">
                        <el-input v-model="form.cidr" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="acl" prop="exclude_acl">
                        <el-select multiple v-model="form.exclude_acl" style="width: 100%">
                            <el-option
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                                    v-for="item in aclsList">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button :loading="EditLoading" @click="handleSubmit" type="primary">Save</el-button>
                        <el-button :disabled="EditLoading" @click="dialogFormVisible = false" type="danger">Cancel
                        </el-button>
                    </el-form-item>
                </el-form>
            </div>
        </el-dialog>
    </div>
</template>


<script>
import {getList, handle, add} from "@/api/dashboard/CIDRList"
import {getList as getServeList} from "@/api/dashboard/acl"

export default {
    data() {
        var validateacls = (rule, value, callback) => {
            if (value.length == 0) {
                callback(new Error('Please select acl'))
            } else {
                callback()
            }
        }
        return {
            formInline: {
                search: '',
            },
            tableData: [],
            currentPage: 1,
            total: 0,
            tableLoading: false,
            dialogFormVisible: false,
            EditLoading: false,
            // viewInfo: {}
            form: {
                cidr: '',
                ip_count: "",
                exclude_acl: []
            },
            handleType: "",
            aclsList: [],
            formLoading: false
        }
    },
    mounted() {
        new this.$wow.WOW().init()
        this.getTableData()
        getServeList({limit: 500, offset: 0}, res => {
            this.aclsList = res.data.data
            // this.formLoading = false
        }, res => {
            // this.formLoading = false
            this.$message.warning(res.msg)
        })
    },
    methods: {
        refreshTableData() {
            this.getTableData()
        },

        onSubmit() {
            this.currentPage = 1
            this.getTableData()
        },
        handleCurrentChange(val) {
            this.getTableData()
        },
        getTableData() {
            const offset = (this.currentPage - 1) * 10
            const param = {search: this.formInline.search, limit: 10, offset}
            this.tableLoading = true
            getList(param, (res) => {
                this.tableData = res.data.data
                this.total = res.data.total
                this.tableLoading = false
            })
        },
        handleEdit(row) {
            this.handleType = "edit"
            this.dialogFormVisible = true
            setTimeout(()=> {
                this.form = Object.assign({}, row);
                this.form.exclude_acl = row.exclude_acl.map(item => item.id);
            },0)
        },
        handleAdd() {
            this.handleType = "add"
            this.dialogFormVisible = true
        },
        handleSubmit() {
            this.$refs["ruleForm"].validate((valid) => {
                if (valid) {
                    this.EditLoading = true
                    if (this.handleType == "edit") {
                        const param = {...this.form}
                        // let arr = []
                        // param.exclude_acl.forEach(ele=> {
                        //     let obj = this.aclsList.find(item=> item.id == ele)
                        //     arr.push({name: obj.name, id:obj.id})
                        // })
                        handle({...param}, (res) => {
                            this.dialogFormVisible = false
                            this.EditLoading = false
                            this.$message.success("successfully")
                            this.getTableData()
                        }, (res) => {
                            this.$message.warning(res.msg)
                            this.EditLoading = false
                        })
                    } else {
                        const param = {...this.form}
                        add(param, (res) => {
                            this.dialogFormVisible = false
                            this.EditLoading = false
                            this.$message.success("successfully")
                            this.getTableData()
                        }, (res) => {
                            this.$message.warning(res.msg)
                            this.EditLoading = false
                        })
                    }
                } else {
                    return false
                }
            })
        },
        handleDelete(row, index) {
            this.$confirm(`Confirm deletion`, 'Delete ?', {
                confirmButtonText: 'YES',
                cancelButtonText: 'NO',
                type: 'warning'
            }).then(() => {
                handle(
                    "delete",
                    {id: row.id},
                    (res) => {
                        this.currentPage = this.currentPage === 1 ? this.currentPage : (this.tableData.length == 1 && index === 0) ? this.currentPage - 1 : this.currentPage
                        this.$message.success("successfully")
                        this.getTableData()
                    },
                )
            }).catch(() => {

            })
        }


    }
}
</script>

<style scoped lang="scss">
.box-card {
  :deep(.el-card) {
    border-radius: 18px;
  }

  :deep(.el-form) {
    font-family: "Pang";
  }

  :deep(thead) {
    font-family: "Pang";
  }

  :deep(.el-form-item__label) {
    font-size: 20px;
    color: #fff;
  }

  :deep(.el-input__inner) {
    background-color: transparent;
    border: 3px solid #fff;
    color: #fff;
  }

  :deep(.el-form-item) {
    margin-bottom: 0;
  }


  .demo-form-inline {
    :deep(.el-button) {
      font-family: "Pang";
      background-color: #d6227a;
      color: #fff;
      border-radius: 10px;
    }
  }

  :deep(.table_header) {
    background: linear-gradient(135deg, #f5f7fa 0%, #e4e7ed 100%);
    color: #303133;
    font-weight: 600;
    padding: 12px 0;
}

  :deep(.table_header:first-child) {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  :deep(.table_header:nth-child(6)) {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  :deep(.el-pagination.is-background .el-pager li:not(.disabled).active) {
    background-color: #f1408c;
  }

    .truncate-tag {
        //max-width: 100%;  /* Or any other width that you find suitable */
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: inline-block;
        margin-right: 5px;
    }
}
</style>