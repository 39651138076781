:deep(.table_header) {
    background: linear-gradient(135deg, #f5f7fa 0%, #e4e7ed 100%);
    color: #303133;
    font-weight: 600;
    padding: 12px 0;
}<template>
    <div>
        <el-card class="box-card" shadow="hover">
            <el-card class="search-card" shadow="hover">
                <div class="card-header">
                    <i class="el-icon-s-operation"></i>
                    <span>Server Group Management</span>
                </div>
                <el-form :inline="true" :model="formInline" class="search-form">
                    <el-form-item>
                        <el-input 
                            v-model="formInline.search" 
                            @change="onSubmit"
                            @keyup.enter.native="onSubmit"
                            placeholder="Search server groups..."
                            prefix-icon="el-icon-search"
                            clearable>
                        </el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button 
                            @click="onSubmit" 
                            type="info"
                            :loading="tableLoading">
                            <i class="el-icon-search"></i>
                            Search
                        </el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button 
                            @click="handleAdd" 
                            type="primary">
                            <i class="el-icon-plus"></i>
                            Add Group
                        </el-button>
                    </el-form-item>
                </el-form>
            </el-card>

            <el-table 
                :data="tableData" 
                style="width: 100%" 
                header-cell-class-name="table_header"
                v-loading="tableLoading"
                :stripe="true"
                :border="true">
                <el-table-column prop="id" label="ID" align="center" width="80">
                </el-table-column>
                <el-table-column prop="name" label="Group Name" align="center">
                </el-table-column>
                <el-table-column label="Servers" align="center">
                    <template v-slot="{ row }">
                        <div class="server-tag-container">
                            <el-tag
                                v-for="item in row.servers"
                                :key="item.id"
                                class="server-tag"
                                type="info">
                                <el-tooltip :content="item.name" placement="top">
                                    <span class="tag-content">{{ item.name }}</span>
                                </el-tooltip>
                            </el-tag>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="description" label="Description" align="center" show-overflow-tooltip>
                </el-table-column>
                <el-table-column label="Actions" align="center" width="200">
                    <template v-slot="{ row, $index }">
                        <el-button 
                            type="text" 
                            @click="handleEdit(row)"
                            class="action-button">
                            <i class="el-icon-edit"></i>
                            Edit
                        </el-button>
                        <el-button 
                            type="text" 
                            @click="handleDelete(row, $index)"
                            class="action-button delete">
                            <i class="el-icon-delete"></i>
                            Delete
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <div class="pagination-container">
                <div class="total-groups">Total Groups: {{ total }}</div>
                <el-pagination 
                    background 
                    layout="->,prev, pager, next" 
                    :total="total"
                    :current-page.sync="currentPage" 
                    @current-change="handleCurrentChange">
                </el-pagination>
            </div>
        </el-card>

        <el-dialog 
            :visible.sync="dialogFormVisible" 
            :title="handleType === 'edit' ? 'Edit Server Group' : 'Add Server Group'"
            center 
            width="40%"
            class="group-dialog"
            @close="refreshTableData">
            <div class="dialog-content">
                <el-form 
                    :model="form" 
                    label-width="120px" 
                    :rules="rules" 
                    ref="ruleForm" 
                    v-loading="formLoading"
                    class="group-form">
                    <el-form-item label="Group Name" prop="name">
                        <el-input v-model="form.name" placeholder="Enter group name"></el-input>
                    </el-form-item>

                    <el-form-item label="Description" prop="description">
                        <el-input 
                            type="textarea" 
                            v-model="form.description" 
                            placeholder="Enter group description"
                            :rows="3">
                        </el-input>
                    </el-form-item>

                    <el-form-item label="Servers" prop="servers">
                        <el-select 
                            v-model="form.servers" 
                            multiple 
                            filterable
                            placeholder="Select servers"
                            class="server-select">
                            <el-option 
                                v-for="item in serversList" 
                                :label="item.name" 
                                :value="item.id" 
                                :key="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button 
                    @click="dialogFormVisible = false" 
                    :disabled="EditLoading">
                    Cancel
                </el-button>
                <el-button 
                    @click="handleSubmit" 
                    type="primary" 
                    :loading="EditLoading">
                    {{ handleType === 'edit' ? 'Update' : 'Create' }}
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { getList, handle, add } from "@/api/dashboard/servergroup"
import { getList as getServeList } from "@/api/dashboard/servers"
export default {
    data() {
        var validateServers = (rule, value, callback) => {
            if (value.length == 0) {
                callback(new Error('Please select server'))
            } else {
                callback()
            }
        }
        return {
            formInline: {
                search: '',
            },
            tableData: [
            ],
            currentPage: 1,
            total: 0,
            tableLoading: false,
            dialogFormVisible: false,
            EditLoading: false,
            // viewInfo: {}
            form: {
                name: '',
                description: "",
                servers: []
            },
            rules: {
                name: [
                    { required: true, message: 'Please enter server group', trigger: 'blur' },
                ],
                description: [
                    { required: true, message: 'Please enter description', trigger: 'blur' },
                ],
                servers: [
                    { validator: validateServers, trigger: 'blur' }
                ]
            },
            handleType: "",
            serversList: [],
            formLoading: false
        }
    },
    mounted() {
        new this.$wow.WOW().init()
        getServeList({ limit: 250, offset: 0 }, res => {
            this.serversList = res.data.data
            this.formLoading = false
        }, res => {
            this.formLoading = false
            this.$message.warning(res.msg)
        })
        this.getTableData()
    },

    methods: {
        refreshTableData() {
            this.getTableData()
        },
        handleError(res) {
            this.$message.warning(res.msg)
            this.EditLoading = false
            this.formLoading = false
        },
        apiCall(method, param, successCallback) {
            if (this.handleType === 'edit') {
                handle(method, param, successCallback, this.handleError)
            } else if (this.handleType === 'add') {
                add(param, successCallback, this.handleError)
            }
        },
        validateForm(callback) {
            this.$refs["ruleForm"].validate((valid) => {
                if (valid) {
                    callback()
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },

        onSubmit() {
            this.currentPage = 1
            this.getTableData()
        },
        handleCurrentChange(val) {
            this.getTableData()
        },
        getTableData() {
            const offset = (this.currentPage - 1) * 10
            const param = { search: this.formInline.search, limit: 10, offset }
            this.tableLoading = true
            getList(param, (res) => {
                this.tableData = res.data.data
                this.total = res.data.total
                this.tableLoading = false
            })
        },
        handleEdit(row) {
            this.handleType = "edit"
            this.dialogFormVisible = true
            this.$nextTick(() => {
                this.form = Object.assign({}, row);
                this.form.servers = row.servers.map(item => item.id);
            })
        },
        handleAdd() {
            this.handleType = "add"
            this.dialogFormVisible = true
        },
        handleSubmit() {
            this.$refs["ruleForm"].validate((valid) => {
                if (valid) {
                    this.EditLoading = true
                    if (this.handleType == "edit") {
                        const param = { ...this.form }
                        this.apiCall("put", param, (res) => {
                            this.dialogFormVisible = false
                            this.EditLoading = false
                            this.$message.success("successfully")
                            this.getTableData()
                        }, (res) => {
                            this.$message.warning(res.msg)
                            this.EditLoading = false
                        })
                    } else {
                        const param = { ...this.form }
                        add(param, (res) => {
                            this.dialogFormVisible = false
                            this.EditLoading = false
                            this.$message.success("successfully")
                            this.getTableData()
                        }, (res) => {
                            this.$message.warning(res.msg)
                            this.EditLoading = false
                        })
                    }
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
        handleDelete(row, index) {
            this.$confirm(`Confirm deletion`, 'Delete ?', {
                confirmButtonText: 'YES',
                cancelButtonText: 'NO',
                type: 'warning'
            }).then(() => {
                handle(
                    "delete",
                    { id: row.id },
                    (res) => {
                        this.currentPage = this.currentPage === 1 ? this.currentPage : (this.tableData.length == 1 && index === 0) ? this.currentPage - 1 : this.currentPage
                        this.$message.success("successfully")
                        this.getTableData()
                    },
                )
            }).catch(() => {

            })
        }



    }
}
</script>

<style lang="scss" scoped>
.box-card {
    margin: 20px;
    transition: all 0.3s ease;

    .search-card {
        margin-bottom: 20px;
        background: linear-gradient(135deg, #ff758c 0%, #ff7eb3 100%);
        
        .card-header {
            display: flex;
            align-items: center;
            margin-bottom: 16px;
            color: #fff;
            
            i {
                font-size: 24px;
                margin-right: 8px;
            }
            
            span {
                font-size: 18px;
                font-weight: 600;
            }
        }

        .search-form {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 10px;

            .el-form-item {
                margin-bottom: 0;
            }

            .el-input {
                width: 240px;
                
                ::v-deep .el-input__inner {
                    border-radius: 4px;
                    background: rgba(255, 255, 255, 0.9);
                    
                    &:focus {
                        background: #fff;
                    }
                }
            }

            .el-button {
                transition: all 0.3s ease;
                
                &:hover {
                    transform: translateY(-2px);
                    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
                }
            }
        }
    }

    .server-tag-container {
        display: flex;
        flex-wrap: wrap;
        gap: 6px;
        justify-content: center;
        align-items: center;
        padding: 4px;

        .server-tag {
            margin: 0;
            background-color: #f5f7fa;
            color: #606266;
            border-color: #e4e7ed;
            transition: all 0.3s ease;
            max-width: 150px;

            .tag-content {
                display: inline-block;
                max-width: 140px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                vertical-align: bottom;
            }

            &:hover {
                transform: translateY(-1px);
                background-color: #ecf5ff;
                color: #409eff;
                border-color: #c6e2ff;
            }
        }
    }

    .action-button {
        padding: 4px 8px;
        font-size: 14px;
        transition: all 0.3s ease;

        i {
            margin-right: 4px;
        }

        &:hover {
            transform: translateY(-1px);
        }

        &.delete:hover {
            color: #f56c6c;
        }
    }

    .pagination-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
        padding: 0 20px;

        .total-groups {
            color: #606266;
            font-size: 14px;
        }
    }
}

:deep(.table_header) {
    background: linear-gradient(135deg, #f5f7fa 0%, #e4e7ed 100%);
    color: #303133;
    font-weight: 600;
    padding: 12px 0;
}

.group-dialog {
    ::v-deep .el-dialog {
        border-radius: 8px;
        overflow: hidden;
        
        .el-dialog__header {
            background: linear-gradient(135deg, #ff758c 0%, #ff7eb3 100%);
            padding: 20px;
            margin: 0;
            
            .el-dialog__title {
                color: #fff;
                font-weight: 600;
            }
        }
        
        .el-dialog__body {
            padding: 30px 20px;
        }
    }

    .dialog-content {
        .group-form {
            .el-form-item {
                margin-bottom: 20px;
                
                ::v-deep .el-form-item__label {
                    font-weight: 500;
                }
                
                ::v-deep .el-input__inner,
                ::v-deep .el-textarea__inner {
                    border-radius: 4px;
                    
                    &:focus {
                        border-color: #ff758c;
                    }
                }

                .server-select {
                    width: 100%;
                }
            }
        }
    }

    .dialog-footer {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
        padding: 10px 20px 20px;
        
        .el-button {
            padding: 12px 24px;
            font-size: 14px;
            border-radius: 4px;
            transition: all 0.3s ease;
            
            &:hover {
                transform: translateY(-2px);
                box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
            }
        }
    }
}

::v-deep .el-table {
    .el-table__row {
        transition: all 0.3s ease;
        
        &:hover {
            background-color: #f5f7fa !important;
            transform: translateY(-2px);
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        }
    }
}
</style>
