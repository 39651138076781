<template>
    <div>
        <el-card class="box-card" shadow="hover">
            <el-card class="search-card" shadow="hover">
                <div class="card-header">
                    <i class="el-icon-user"></i>
                    <span>User Management</span>
                </div>
                <el-form :inline="true" :model="formInline" class="search-form">
                    <el-form-item>
                        <el-input 
                            v-model="formInline.search" 
                            @change="onSubmit"
                            @keyup.enter.native="onSubmit"
                            placeholder="Search users..."
                            prefix-icon="el-icon-search"
                            clearable>
                        </el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button 
                            @click="onSubmit" 
                            type="primary"
                            :loading="tableLoading">
                            <i class="el-icon-search"></i>
                            Search
                        </el-button>
                    </el-form-item>
                </el-form>
            </el-card>
            <el-table :data="tableData" style="width: 100%" header-cell-class-name="table_header"
                      v-loading="tableLoading" stripe>
                <el-table-column prop="username" label="Name" align="center" width="130" sortable>
                </el-table-column>
                <el-table-column prop="email" label="Email" align="center" width="130" sortable>
                </el-table-column>
                <el-table-column label="Baned" align="center" sortable>
                    <template v-slot="{ row }">
                        <div>
                            <el-tag v-if="!row.is_active" type="success">YES</el-tag>
                            <el-tag v-else type="danger">NO</el-tag>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="reward_points" label="Coins" align="center" sortable>
                </el-table-column>
                <el-table-column label="Discord" align="center" sortable>
                    <template v-slot="{ row }">
                        <el-button 
                            type="primary" 
                            size="small" 
                            @click="openDiscord(row.discord_name)"
                            :disabled="!row.discord_name">
                            <i class="el-icon-position"></i>
                            {{ row.discord_name || 'Not Set' }}
                        </el-button>
                    </template>
                </el-table-column>
                <el-table-column label="Admin" align="center">
                    <template v-slot="{ row }">
                        <div>
                            <el-tag v-if="row.is_superuser" type="success">YES</el-tag>
                            <el-tag v-else type="danger">NO</el-tag>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="level" label="Level" align="center" sortable>
                </el-table-column>
                <el-table-column prop="last_location" label="IP Loc." align="center" sortable>
                    <template v-slot="{ row }">
                        <el-tooltip class="item" effect="dark" :content="row.last_login_ip" placement="top">
                            <a :href="'https://iplocation.io/ip/' + row.last_login_ip"
                               target="_blank">{{ row.last_location }} {{ getCountryFlag(row.last_login_ip) }}</a>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column label="Operation" align="center" width="100" fixed="right">
                    <template v-slot="{ row, $index }">
                        <el-dropdown @command="handleCommand($event, row, $index)" trigger="click">
                            <el-button type="primary" size="small">
                                Actions <i class="el-icon-arrow-down el-icon--right"></i>
                            </el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item v-if="!row.is_active" command="unban">
                                    <i class="el-icon-check"></i> UnBan
                                </el-dropdown-item>
                                <el-dropdown-item v-else command="ban">
                                    <i class="el-icon-close"></i> Ban
                                </el-dropdown-item>
                                <el-dropdown-item command="edit">
                                    <i class="el-icon-edit"></i> Edit
                                </el-dropdown-item>
                                <el-dropdown-item command="delete" divided>
                                    <i class="el-icon-delete"></i> Delete
                                </el-dropdown-item>
                                <el-dropdown-item command="order" divided>
                                    <i class="el-icon-document"></i> Order
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </el-table-column>
                <el-table-column prop="created_at" label="Create" align="center" width="120">
                    <template v-slot="{ row }">
                        <el-tooltip class="item" effect="dark" :content="row.created_at" placement="top-start"
                                    :content-style="{ 'max-height': '60px' }">
                            <div class="ellipsis">{{ row.created_at.substr(0, 10) }}</div>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column prop="last_login" label="Last Login" align="center" width="120">
                    <template v-slot="{ row }">
                        <el-tooltip class="item" effect="dark" :content="row.created_at" placement="top-start"
                                    :content-style="{ 'max-height': '60px' }">
                            <div class="ellipsis">{{ row.created_at.substr(0, 10) }}</div>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination-container">
                <div class="total-users">Total Users: {{ total }}</div>
                <el-pagination 
                    background 
                    layout="prev, pager, next" 
                    :total="total"
                    :current-page.sync="currentPage" 
                    @current-change="handleCurrentChange">
                </el-pagination>
            </div>
        </el-card>

        <el-dialog 
            :title="'Edit User: ' + form.username"
            :visible.sync="dialogFormVisible"
            width="80%"
            class="edit-dialog"
            :close-on-click-modal="false">
            <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px">
                <div class="edit-cards-container">
                    <!-- User Info Card -->
                    <el-card class="edit-card" shadow="hover">
                        <div slot="header" class="card-header">
                            <i class="el-icon-user"></i>
                            <span>User Information</span>
                        </div>
                        <div class="card-content">
                            <div class="info-item">
                                <span class="label">Username:</span>
                                <el-tag type="success">{{ form.username }}</el-tag>
                            </div>
                            <div class="info-item">
                                <span class="label">Created:</span>
                                <el-tag type="info">{{ form.created_at }}</el-tag>
                            </div>
                            <div class="info-item">
                                <span class="label">Status:</span>
                                <el-tag :type="form.is_active ? 'success' : 'danger'">
                                    {{ form.is_active ? 'Active' : 'Banned' }}
                                </el-tag>
                            </div>
                            <div class="info-item">
                                <span class="label">Level:</span>
                                <el-tag type="info">{{ form.level }}</el-tag>
                            </div>
                            <div class="info-item">
                                <span class="label">Invite Count:</span>
                                <el-tag type="info">{{ form.invite_count }}</el-tag>
                            </div>
                        </div>
                    </el-card>

                    <!-- Account Management Card -->
                    <el-card class="edit-card" shadow="hover">
                        <div slot="header" class="card-header">
                            <i class="el-icon-setting"></i>
                            <span>Account Management</span>
                        </div>
                        <div class="card-content">
                            <el-form-item label="Email">
                                <el-tag>{{ form.email }}</el-tag>
                            </el-form-item>
                            <el-form-item label="Discord">
                                <el-button 
                                    type="primary" 
                                    size="small" 
                                    @click="openDiscord(form.discord_name)"
                                    :disabled="!form.discord_name">
                                    <i class="el-icon-position"></i>
                                    {{ form.discord_name || 'Not Set' }}
                                </el-button>
                            </el-form-item>
                            <el-form-item label="Admin">
                                <el-switch v-model="form.is_superuser" active-color="#13ce66" inactive-color="#ff4949">
                                </el-switch>
                            </el-form-item>
                            <el-form-item label="Coins" prop="reward_points">
                                <el-input-number 
                                    v-model="form.reward_points" 
                                    controls-position="right" 
                                    :min="0"
                                    :max="100000000"
                                    style="width: 100%;">
                                </el-input-number>
                            </el-form-item>
                            <el-form-item label="Password">
                                <el-input 
                                    v-model="password" 
                                    type="password" 
                                    placeholder="Enter new password"
                                    show-password
                                    @focus="handlePassword">
                                </el-input>
                                <div class="button-group" style="margin-top: 10px;">
                                    <el-button 
                                        type="primary" 
                                        @click="resetPassword" 
                                        :loading="resetpasswordLoading">
                                        <i class="el-icon-key"></i>
                                        Reset Password
                                    </el-button>
                                </div>
                            </el-form-item>
                        </div>
                    </el-card>

                    <!-- Additional Info Card -->
                    <el-card class="edit-card" shadow="hover">
                        <div slot="header" class="card-header">
                            <i class="el-icon-info"></i>
                            <span>Additional Information</span>
                        </div>
                        <div class="card-content">
                            <div class="info-item">
                                <span class="label">Last Login:</span>
                                <el-tag type="info">{{ form.last_login_time || 'Never' }}</el-tag>
                            </div>
                            <div class="info-item">
                                <span class="label">Last IP:</span>
                                <el-tag type="info">{{ form.last_login_ip }} {{ getCountryFlag(form.last_login_ip) }}</el-tag>
                            </div>
                            <div class="info-item">
                                <span class="label">Location:</span>
                                <el-tag type="info">{{ form.last_location || 'Unknown' }}</el-tag>
                            </div>
                        </div>
                    </el-card>
                </div>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">Cancel</el-button>
                <el-button type="primary" @click="dialogOnSubmit" :loading="submitLoading">Save</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {getUserList, banUser, unbanUser, deleteUserApi, resetPasswordApi, updataUserApi} from "@/api/dashboard/userList"

export default {
    data() {
        return {
            msg: "userList",
            formInline: {
                search: '',
            },
            tableData: [],
            currentPage: 1,
            total: 0,
            tableLoading: false,
            dialogFormVisible: false,
            form: {
                username: "4564564",
                email: "1323123#qwe123",
                discord_name: "asdasdasda"
            },
            password: "",
            rules: {
                reward_points: [
                    {required: true, message: 'Please enter points', trigger: 'blur'},

                ],
            },
            submitLoading: false,
            resetpasswordLoading: false,
            countryFlags: {
                'CN': '🇨🇳',
                'US': '🇺🇸',
                'JP': '🇯🇵',
                'KR': '🇰🇷',
                'GB': '🇬🇧',
                'DE': '🇩🇪',
                'FR': '🇫🇷',
                'IT': '🇮🇹',
                'RU': '🇷🇺',
                'BR': '🇧🇷',
                'IN': '🇮🇳',
                'AU': '🇦🇺',
                'CA': '🇨🇦',
                'HK': '🇭🇰',
                'MY': '🇲🇾',
                'SG': '🇸🇬',
                'TW': '🇹🇼',
                'TH': '🇹🇭',
                'VN': '🇻🇳',
                'ID': '🇮🇩'
            }
        }
    },
    mounted() {
        new this.$wow.WOW().init()
        this.getTableData()
    },
    methods: {
        handlePassword() {
            if (this.password == "qazmkoiuytr") this.password = ""
        },

        onSubmit() {
            this.currentPage = 1
            this.getTableData()
        },
        handleCurrentChange(val) {
            this.getTableData()
        },
        getTableData() {
            const offset = (this.currentPage - 1) * 10
            const param = {search: this.formInline.search, limit: 10, offset}
            this.tableLoading = true
            getUserList(param, (res) => {

                this.tableData = res.data.data
                this.total = res.data.total
                this.tableLoading = false
            })
        },

        handleCommand(command, row, $index) {
            switch (command) {
                case 'ban':
                    this.$confirm(`UserName: ${row.username}`, 'Ban User ?', {
                        confirmButtonText: 'YES',
                        cancelButtonText: 'NO',
                        type: 'warning'
                    }).then(() => {
                        banUser({id: row.id}, (res) => {
                            this.getTableData()
                        })
                    }).catch(() => {

                    })
                    break;
                case 'unban':
                    this.$confirm(`UserName: ${row.username}`, 'UnBan User ?', {
                        confirmButtonText: 'YES',
                        cancelButtonText: 'NO',
                        type: 'warning'
                    }).then(() => {
                        unbanUser({id: row.id}, (res) => {
                            this.getTableData()
                        })
                    }).catch(() => {

                    })
                    break;
                case 'edit':
                    this.dialogFormVisible = true
                    this.form = row
                    this.password = ""
                    break;
                case 'delete':
                    this.$confirm(`UserName: ${row.username}`, 'Delete User ?', {
                        confirmButtonText: 'YES',
                        cancelButtonText: 'NO',
                        type: 'warning'
                    }).then(() => {
                        deleteUserApi({id: row.id}, (res) => {
                            this.currentPage = this.currentPage === 1 ? this.currentPage : (this.tableData.length == 1 && $index === 0) ? this.currentPage - 1 : this.currentPage
                            this.getTableData()
                        })
                    }).catch(() => {

                    })
                    break;
                case 'order':
                    this.$router.push({ name: 'Orders', params: { username: row.username } })
                    break;
            }
        },
        // 修改密码
        resetPassword() {

            if (!this.password) return this.$message.warning("Please enter password")

            this.$confirm(`RESET PASSWORD`, 'Confirm password change', {
                confirmButtonText: 'YES',
                cancelButtonText: 'NO',
                type: 'warning'
            }).then(() => {
                this.resetpasswordLoading = true
                resetPasswordApi({id: this.form.id, password: this.password}, (res) => {
                    this.resetpasswordLoading = false
                    this.$message.success("success")
                })
            }).catch(() => {

            })
        },

        // dialog保存
        dialogOnSubmit() {
            this.$refs['ruleForm'].validate((valid) => {
                if (valid) {
                    this.submitLoading = true
                    updataUserApi(this.form, (res) => {
                        this.submitLoading = false
                        this.dialogFormVisible = false
                        this.getTableData()
                    })
                }
            })

        },
        openDiscord(discord_name) {
            if (!discord_name) return
            // For direct messages we need the Discord ID
            const discordId = discord_name.split('#')[0]
            window.open(`https://discord.com/users/${discordId}`, '_blank')
        },
        getCountryFlag(ip) {
            const row = this.tableData.find(item => item.last_login_ip === ip)
            if (!row || !row.last_location) return '🌍'
            
            return this.countryFlags[row.last_location] || '🌍'
        }
    }
}
</script>

<style scoped lang="scss">
.box-card {
  margin: 20px;

  :deep(.el-card) {
    border-radius: 8px;
    transition: all 0.3s ease;

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    }
  }

  .search-card {
    margin-bottom: 20px;
    background: linear-gradient(135deg, #ff758c 0%, #ff7eb3 100%);
    
    .card-header {
      display: flex;
      align-items: center;
      padding: 16px 20px;
      color: #fff;
      
      i {
        font-size: 24px;
        margin-right: 12px;
      }
      
      span {
        font-size: 18px;
        font-weight: 600;
      }
    }

    .search-form {
      padding: 16px 20px;
      display: flex;
      align-items: center;
      gap: 12px;

      .el-form-item {
        margin-bottom: 0;
      }

      .el-input {
        width: 300px;
        
        :deep(.el-input__inner) {
          border-radius: 20px;
          height: 40px;
          background: rgba(255, 255, 255, 0.9);
          border: none;
          
          &:focus {
            background: #fff;
            box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2);
          }
        }

        :deep(.el-input__prefix) {
          left: 12px;
        }
      }

      .el-button {
        height: 40px;
        padding: 0 24px;
        font-weight: 500;
        border-radius: 20px;
        transition: all 0.3s ease;
        
        &:hover {
          background: #fff;
          transform: translateY(-1px);
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
        }

        i {
          margin-right: 4px;
        }
      }
    }
  }

  :deep(.el-table) {
    border-radius: 8px;
    overflow: hidden;
    
    .el-table__header {
      background: #f5f7fa;
    }

    .el-table__row {
      transition: all 0.3s;
      
      &:hover {
        background-color: #f5f7fa;
      }
    }

    .el-button--text {
      padding: 4px 8px;
      font-weight: 500;
      
      &:hover {
        color: #ff758c;
        background: rgba(255, 117, 140, 0.1);
        border-radius: 4px;
      }
    }
  }

  .el-tag {
    border-radius: 12px;
    padding: 0 12px;
    height: 24px;
    line-height: 22px;
    
    &.el-tag--success {
      background-color: #95e1d3;
      border-color: #95e1d3;
      color: #ffffff;
    }
    
    &.el-tag--danger {
      background-color: #ff9a9e;
      border-color: #ff9a9e;
      color: #ffffff;
    }
  }

  .pagination-container {
    margin-top: 24px;
    padding: 0 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .total-users {
      font-size: 14px;
      color: #606266;
      font-weight: 500;
    }

    :deep(.el-pagination) {
      .btn-prev, .btn-next {
        border-radius: 4px;
      }

      .el-pager li {
        border-radius: 4px;
        
        &.active {
          background-color: #ff758c;
        }
      }
    }
  }
}

.edit-dialog {
  :deep(.el-dialog) {
    border-radius: 8px;
    overflow: hidden;

    .el-dialog__header {
      padding: 20px;
      background: linear-gradient(135deg, #ff758c 0%, #ff7eb3 100%);
      margin-right: 0;

      .el-dialog__title {
        color: #fff;
        font-size: 18px;
        font-weight: 600;
      }
    }

    .el-dialog__body {
      padding: 24px;
    }

    .el-form-item__label {
      font-weight: 500;
      color: #606266;
    }

    .el-tag {
      font-size: 14px;
    }

    .el-button {
      padding: 8px 20px;
      font-weight: 500;
      border-radius: 20px;
      transition: all 0.3s ease;

      &:hover {
        transform: translateY(-1px);
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
      }
    }

  }
}

.edit-cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  .edit-card {
    width: calc(33.33% - 20px);
    margin: 10px;

    .card-header {
      display: flex;
      align-items: center;
      padding: 16px 20px;
      color: #fff;
      background: linear-gradient(135deg, #ff758c 0%, #ff7eb3 100%);

      i {
        font-size: 24px;
        margin-right: 12px;
      }

      span {
        font-size: 18px;
        font-weight: 600;
      }
    }

    .card-content {
      padding: 20px;

      .info-item {
        margin-bottom: 16px;
        display: flex;
        align-items: center;

        .label {
          font-weight: 500;
          color: #606266;
          margin-right: 8px;
        }
      }

      .form-item {
        margin-bottom: 16px;

        .el-form-item__label {
          font-weight: 500;
          color: #606266;
        }
      }

      .button-group {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}

:deep(.table_header) {
    background: linear-gradient(135deg, #f5f7fa 0%, #e4e7ed 100%);
    color: #303133;
    font-weight: 600;
    padding: 12px 0;
}

.operation-buttons {
  display: flex;
  gap: 8px;
  justify-content: center;
  flex-wrap: wrap;

  :deep(.el-button) {
    margin: 0;
    padding: 4px 8px;
    height: 28px;
    font-size: 12px;
    transition: all 0.3s ease;

    &:hover {
      transform: translateY(-1px);
      opacity: 0.9;
    }

    i {
      margin-right: 4px;
      font-size: 12px;
    }
  }
}

:deep(.el-dropdown) {
  .el-button {
    width: 85px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 12px;
    
    i {
      margin-left: 4px;
    }
  }
}

:deep(.el-dropdown-menu) {
  padding: 4px 0;
  
  .el-dropdown-menu__item {
    padding: 8px 16px;
    font-size: 13px;
    line-height: 1.4;
    display: flex;
    align-items: center;
    transition: all 0.3s ease;

    i {
      margin-right: 8px;
      font-size: 14px;
    }

    &:hover {
      background-color: #f5f7fa;
      color: #409EFF;
    }

    &.is-disabled {
      opacity: 0.6;
    }
  }
}
</style>