<template>
    <div>
        <el-card class="box-card" shadow="hover">
            <el-card class="search-card" shadow="hover">
                <div class="card-header">
                    <i class="el-icon-key"></i>
                    <span>ACL Management</span>
                </div>
                <el-form :inline="true" :model="formInline" class="search-form">
                    <el-form-item>
                        <el-input 
                            v-model="formInline.search" 
                            @change="onSubmit"
                            @keyup.enter.native="onSubmit"
                            placeholder="Search ACL rules..."
                            prefix-icon="el-icon-search"
                            clearable>
                        </el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button 
                            @click="onSubmit" 
                            type="info"
                            :loading="tableLoading">
                            <i class="el-icon-search"></i>
                            Search
                        </el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button 
                            @click="handleAdd" 
                            type="primary">
                            <i class="el-icon-plus"></i>
                            Add Rule
                        </el-button>
                    </el-form-item>
                </el-form>
            </el-card>
            
            <el-table 
                :data="tableData" 
                style="width: 100%" 
                header-cell-class-name="table_header"
                v-loading="tableLoading"
                :stripe="true"
                :border="true">
                <el-table-column prop="id" label="id" align="center" width="50">
                </el-table-column>
                <el-table-column prop="name" label="name" align="center" width="80">
                </el-table-column>
                <el-table-column prop="price" label="price" align="center" width="60">
                </el-table-column>
                <el-table-column prop="shopify_variant_id" label="Shopify Variant Id" align="center" width="140">
                </el-table-column>
                <el-table-column prop="description" label="description" align="center" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="acl_value" label="Rule" align="center" width="230">
                    <template v-slot="{ row }">
                        <div class="tag-container">
                            <el-tag 
                                v-for="item in row.aclList" 
                                :key="item"
                                class="rule-tag">
                                {{ item }}
                            </el-tag>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="created_at" label="Create Time" align="center" width="112">
                    <template v-slot="{ row }">
                        <el-tooltip class="item" effect="dark" :content="row.created_at" placement="top-start"
                                    :content-style="{ 'max-height': '60px' }">
                            <div class="ellipsis">{{ row.created_at.substr(0, 10) }}</div>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column label="Action" align="center" width="130">
                    <template v-slot="{ row, $index }">
                        <el-button type="text" @click="handleEdit(row)">Edit</el-button>
                        <el-button type="text" @click="handleDelete(row, $index)">Delete</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <div class="pagination-container">
                <div class="total-rules">Total Rules: {{ total }}</div>
                <el-pagination 
                    background 
                    layout="->,prev, pager, next" 
                    :total="total" 
                    :current-page.sync="currentPage" 
                    @current-change="handleCurrentChange">
                </el-pagination>
            </div>
        </el-card>

        <el-dialog 
            :visible.sync="dialogFormVisible" 
            :title="handleType === 'edit' ? 'Edit ACL Rule' : 'Add ACL Rule'"
            center 
            width="35%"
            class="acl-dialog">
            <div class="dialog-content">
                <el-form 
                    :model="form" 
                    label-width="150px" 
                    :rules="rules" 
                    ref="ruleForm"
                    class="acl-form">
                    <el-form-item label="name" prop="name">
                        <el-input v-model="form.name"></el-input>
                    </el-form-item>
                    
                    <el-form-item label="price" prop="price">
                        <el-input v-model="form.price"></el-input>
                    </el-form-item>
                    <el-form-item label="Shopify Variant Id" prop="shopify_variant_id">
                        <el-input v-model="form.shopify_variant_id"></el-input>
                    </el-form-item>
                    <el-form-item label="description" prop="description">
                        <el-input type="textarea" v-model="form.description" autosize></el-input>
                    </el-form-item>
                    <el-form-item label="Rules" prop="acl_value">
                        <el-input type="textarea" v-model="form.acl_value" autosize></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button 
                    @click="dialogFormVisible = false" 
                    :disabled="EditLoading">
                    Cancel
                </el-button>
                <el-button 
                    @click="handleSubmit" 
                    type="primary" 
                    :loading="EditLoading">
                    {{ handleType === 'edit' ? 'Update' : 'Create' }}
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {getList, handleAcl, addAcl} from "@/api/dashboard/acl"

export default {
    data() {
        return {
            allRules: '',
            showAll: false,
            showDialog: false,
            formInline: {
                search: '',
            },
            tableData: [],
            currentPage: 1,
            total: 0,
            tableLoading: false,
            dialogFormVisible: false,
            EditLoading: false,
            // viewInfo: {}
            form: {
                name: '',
                description: "",
                acl_value: "",
                shopify_variant_id: "",
                price: ""
            },
            rules: {
                name: [
                    {required: true, message: 'Please enter name', trigger: 'blur'},
                ],
                price: [
                    {required: true, message: 'Please enter price', trigger: 'blur'},
                ],
                shopify_variant_id: [
                    {required: true, message: 'Please enter Shopify Variant Id', trigger: 'blur'},
                ],
                acl_value: [
                    {required: true, message: 'Please enter rule', trigger: 'blur'},
                ],
                description: [
                    {required: true, message: 'Please enter description', trigger: 'blur'},
                ],
            },
            handleType: ""
        }
    },
    mounted() {
        new this.$wow.WOW().init()
        this.getTableData()
    },
    methods: {
        showRules() {
            this.showDialog = true;
            this.allRules = this.row.aclList.join('\n');
        },
        handlePassword() {
            if (this.password == "qazmkoiuytr") this.password = ""
        },

        onSubmit() {
            this.currentPage = 1
            this.getTableData()
        },
        handleCurrentChange(val) {
            this.getTableData()
        },
        getTableData() {
            const offset = (this.currentPage - 1) * 10
            const param = {search: this.formInline.search, limit: 10, offset}
            this.tableLoading = true
            getList(param, (res) => {

                this.tableData = res.data.data.map(item => {
                    item.aclList = item.acl_value.split('\n')
                    return item
                })
                this.total = res.data.total
                this.tableLoading = false
            })
        },
        handleEdit(row) {
            this.handleType = "edit"
            this.dialogFormVisible = true
            this.$nextTick(() => {
                // this.$refs["ruleForm"].resetFields()
                this.form = row
            })
        },
        handleAdd() {
            this.handleType = "add"
            this.form = {
                name: '',
                price: "",
                shopify_variant_id: "",
                description: "",
                acl_value: ""
            }
            this.dialogFormVisible = true
            this.$nextTick(() => {
                this.$refs["ruleForm"].resetFields()
            })
        },
        handleSubmit() {
            this.$refs["ruleForm"].validate((valid) => {
                if (valid) {
                    this.EditLoading = true
                    if (this.handleType == "edit") {
                        handleAcl("put", this.form, (res) => {
                            this.dialogFormVisible = false
                            this.EditLoading = false
                            this.$message.success("successfully")
                            this.getTableData()
                        }, (res) => {
                            this.$message.warning(res.msg)
                            this.EditLoading = false
                        })
                    } else {
                        //
                        addAcl(this.form, (res) => {
                            this.dialogFormVisible = false
                            this.EditLoading = false
                            this.$message.success("successfully")
                            this.getTableData()
                        }, (res) => {
                            this.$message.warning(res.msg)
                            this.EditLoading = false
                        })
                    }
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
        handleDelete(row, index) {
            this.$confirm(`Confirm deletion`, 'Delete ?', {
                confirmButtonText: 'YES',
                cancelButtonText: 'NO',
                type: 'warning'
            }).then(() => {
                handleAcl(
                    "delete",
                    {id: row.id},
                    (res) => {
                        this.currentPage = this.currentPage === 1 ? this.currentPage : (this.tableData.length == 1 && index === 0) ? this.currentPage - 1 : this.currentPage
                        this.$message.success("successfully")
                        this.getTableData()
                    },
                )
            }).catch(() => {

            })
        }


    }
}
</script>

<style lang="scss" scoped>
.box-card {
    margin: 20px;
    transition: all 0.3s ease;

    .search-card {
        margin-bottom: 20px;
        background: linear-gradient(135deg, #ff758c 0%, #ff7eb3 100%);
        
        .card-header {
            display: flex;
            align-items: center;
            margin-bottom: 16px;
            color: #fff;
            
            i {
                font-size: 24px;
                margin-right: 8px;
            }
            
            span {
                font-size: 18px;
                font-weight: 600;
            }
        }

        .search-form {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 10px;

            .el-form-item {
                margin-bottom: 0;
            }

            .el-input {
                width: 240px;
                
                ::v-deep .el-input__inner {
                    border-radius: 4px;
                    background: rgba(255, 255, 255, 0.9);
                    
                    &:focus {
                        background: #fff;
                    }
                }
            }

            .el-button {
                transition: all 0.3s ease;
                
                &:hover {
                    transform: translateY(-2px);
                    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
                }
            }
        }
    }

    .tag-container {
        display: flex;
        flex-wrap: wrap;
        gap: 4px;
        justify-content: center;
        align-items: center;
        max-height: 100px;
        overflow: auto;
        padding: 4px;

        .rule-tag {
            margin: 0;
            background-color: #f5f7fa;
            color: #606266;
            border-color: #e4e7ed;
            transition: all 0.3s ease;

            &:hover {
                transform: translateY(-1px);
                background-color: #ecf5ff;
                color: #409eff;
                border-color: #c6e2ff;
            }
        }
    }

    .pagination-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
        padding: 0 20px;

        .total-rules {
            color: #606266;
            font-size: 14px;
        }
    }
}

:deep(.table_header) {
    background: linear-gradient(135deg, #f5f7fa 0%, #e4e7ed 100%);
    color: #303133;
    font-weight: 600;
    padding: 12px 0;
}

.acl-dialog {
    ::v-deep .el-dialog {
        border-radius: 8px;
        overflow: hidden;
        
        .el-dialog__header {
            background: linear-gradient(135deg, #ff758c 0%, #ff7eb3 100%);
            padding: 20px;
            margin: 0;
            
            .el-dialog__title {
                color: #fff;
                font-weight: 600;
            }
        }
        
        .el-dialog__body {
            padding: 30px 20px;
        }
    }

    .dialog-content {
        .acl-form {
            .el-form-item {
                margin-bottom: 20px;
                
                ::v-deep .el-form-item__label {
                    font-weight: 500;
                }
                
                ::v-deep .el-input__inner,
                ::v-deep .el-textarea__inner {
                    border-radius: 4px;
                    
                    &:focus {
                        border-color: #ff758c;
                    }
                }
            }
        }
    }

    .dialog-footer {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
        padding: 10px 20px 20px;
        
        .el-button {
            padding: 12px 24px;
            font-size: 14px;
            border-radius: 4px;
            transition: all 0.3s ease;
            
            &:hover {
                transform: translateY(-2px);
                box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
            }
        }
    }
}

::v-deep .el-table {
    .el-table__row {
        transition: all 0.3s ease;
        
        &:hover {
            background-color: #f5f7fa !important;
            transform: translateY(-2px);
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        }
    }
}
</style>