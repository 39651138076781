<template>
    <div>
        <el-card class="box-card" shadow="hover">
            <div v-if="!editVisible">
                <el-card class="search-card" shadow="hover" style="background-color: #febac3;">
                    <el-form :inline="true" :model="formInline" class="search-form">
                        <el-form-item>
                            <el-input 
                                v-model="formInline.search" 
                                @change="onSubmit"
                                @keyup.enter.native="onSubmit"
                                placeholder="Search orders..."
                                prefix-icon="el-icon-search"
                                clearable>
                            </el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button 
                                @click="onSubmit" 
                                type="info"
                                :loading="searchLoading">
                                <i class="el-icon-search"></i>
                                Search
                            </el-button>
                        </el-form-item>
                    </el-form>
                    <el-form :inline="true" :model="reset_order" class="reset-form">
                        <el-form-item>
                            <el-input 
                                v-model="reset_order.order_ids" 
                                placeholder="id1,id2,id3"
                                @keyup.enter.native="oneKeyReset"
                                clearable>
                            </el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button 
                                @click="oneKeyReset" 
                                type="info"
                                :loading="resetLoading">
                                <i class="el-icon-refresh"></i>
                                Reset Proxy
                            </el-button>
                        </el-form-item>
                    </el-form>
                </el-card>
                <el-table 
                    ref="orderTable"
                    :data="tableData" 
                    style="width: 100%" 
                    header-cell-class-name="table_header"
                    v-loading="tableLoading" 
                    :cell-style="tableRowClassName" 
                    show-summary
                    stripe
                    border
                    @sort-change="handleSortChange">
                    <el-table-column type="expand">
                        <template slot-scope="props">
                            <el-card class="expand-card" shadow="never">
                                <div class="expand-content">
                                    <div class="expand-section">
                                        <div class="section-title">
                                            <i class="el-icon-shopping-cart-2"></i>
                                            Order & Product Info
                                        </div>
                                        <div class="section-content">
                                            <el-scrollbar class="scroll-container">
                                                <div class="scrollable-row">
                                                    <div class="info-row">
                                                        <div class="info-item">
                                                            <span class="label">Order No:</span>
                                                            <span class="value">{{ props.row.shopify_order_number }}</span>
                                                        </div>
                                                        <div class="info-item">
                                                            <span class="label">Amount:</span>
                                                            <span class="value">${{ props.row.pay_amount }}</span>
                                                        </div>
                                                        <div class="info-item">
                                                            <span class="label">Status:</span>
                                                            <el-tag size="small" :type="props.row.pay_status === 1 ? 'success' : 'danger'">
                                                                {{ payMap[props.row.pay_status] }}
                                                            </el-tag>
                                                        </div>
                                                        <div class="info-item">
                                                            <span class="label">Created:</span>
                                                            <span class="value">{{ props.row.created_at.split(' ')[0] }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </el-scrollbar>
                                            <el-scrollbar class="scroll-container">
                                                <div class="scrollable-row">
                                                    <div class="info-row">
                                                        <div class="info-item">
                                                            <span class="label">Product:</span>
                                                            <span class="value">{{ props.row.product_name }}</span>
                                                        </div>
                                                        <div class="info-item">
                                                            <span class="label">Variant:</span>
                                                            <span class="value">{{ props.row.variant_name }}</span>
                                                        </div>
                                                        <div class="info-item">
                                                            <span class="label">Delivery:</span>
                                                            <el-tag size="small" :type="props.row.delivery_num === 0 ? 'danger' : 'success'">
                                                                {{ props.row.delivery_num }}
                                                            </el-tag>
                                                        </div>
                                                        <div class="info-item">
                                                            <span class="label">Proxy:</span>
                                                            <span class="value">{{ props.row.proxy_num }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </el-scrollbar>
                                        </div>
                                    </div>

                                    <div class="expand-section">
                                        <div class="section-title">
                                            <i class="el-icon-user"></i>
                                            User Info
                                        </div>
                                        <div class="section-content">
                                            <el-scrollbar class="scroll-container">
                                                <div class="scrollable-row">
                                                    <div class="info-row">
                                                        <div class="info-item">
                                                            <span class="label">Username:</span>
                                                            <span class="value">{{ props.row.username }}</span>
                                                        </div>
                                                        <div class="info-item">
                                                            <span class="label">ACLs:</span>
                                                            <span class="value">{{ props.row.acl_selected || 'None' }}</span>
                                                        </div>
                                                        <div class="info-item">
                                                            <span class="label">Expires:</span>
                                                            <span class="value">{{ props.row.expired_at.split(' ')[0] }}</span>
                                                        </div>
                                                        <div class="info-item">
                                                            <span class="label">Reset:</span>
                                                            <span class="value">{{ props.row.reset_time ? props.row.reset_time.split(' ')[0] : 'Not yet' }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </el-scrollbar>
                                        </div>
                                    </div>
                                </div>
                            </el-card>
                        </template>
                    </el-table-column>
                    <el-table-column prop="id" label="ID" align="center" width="80" sortable>
                    </el-table-column>
                  <el-table-column prop="old_flag" label="Old" align="center" sortable>
                    <template v-slot="{ row }">
                      <el-tag v-if="row.old_flag === 1" type="danger" style="width: 100%">
                        {{ oldMap[row.old_flag] }}
                      </el-tag>
                      <el-tag v-else type="success" style="width: 100%">{{ oldMap[row.old_flag] }}</el-tag>
                    </template>
                  </el-table-column>
                    <el-table-column prop="shopify_order_number" label="Order No." align="center" width="80" sortable>
                        <template v-slot="{ row }">
                            <a :href="'https://mentosproxy-2.myshopify.com/admin/orders/'+row.shopify_order_id"
                               target="_blank">{{ row.shopify_order_number }}</a>
                        </template>

                    </el-table-column>
                    <el-table-column prop="username" label="User" align="center">
                    </el-table-column>
                    <el-table-column prop="product_name" label="Product" align="center" width="180px">
                        <template v-slot="{ row }">
                            <el-tooltip class="item" effect="dark" :content="row.product_name+'->'+row.variant_name"
                                        placement="top-start"
                                        :content-style="{ 'max-height': '60px' }">
                                <div class="ellipsis">
                                    <el-tag type="success">{{ row.product_name }}->{{ row.variant_name }}</el-tag>
                                </div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column prop="pay_amount" label="Bill" align="center" sortable>
                        <template v-slot="{ row }">
                            <span>${{ row.pay_amount }}</span>
                        </template>
                    </el-table-column>

                    <el-table-column prop="proxy_num" label="Proxy" align="center">
                    </el-table-column>
                    <el-table-column prop="delivery_num" label="Delivery" align="center" sortable>
                        <template v-slot="{ row }">
                            <el-tag v-if="row.delivery_num === 0" type="danger">{{ row.delivery_num }}</el-tag>
                            <el-tag v-else type="success">{{ row.delivery_num }}</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="pay_status" label="Paid" align="center" sortable>
                        <template v-slot="{ row }">
                            <el-tag v-if="row.pay_status === 0" type="danger" style="width: 100%">
                                {{ payMap[row.pay_status] }}
                            </el-tag>
                            <el-tag v-else type="success" style="width: 100%">{{ payMap[row.pay_status] }}</el-tag>
                        </template>
                    </el-table-column>

                    <el-table-column label="Operation" align="center" width="100px" fixed="right">
                        <template v-slot="{ row, $index }">
                            <el-dropdown @command="handleCommand($event, row, $index)" trigger="click">
                                <el-button type="primary" size="small">
                                    Actions <i class="el-icon-arrow-down el-icon--right"></i>
                                </el-button>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item command="edit">
                                        <i class="el-icon-edit"></i> Edit
                                    </el-dropdown-item>
                                    <el-dropdown-item command="delete" divided>
                                        <i class="el-icon-delete"></i> Delete
                                    </el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </template>
                    </el-table-column>
                    <el-table-column prop="created_at" label="Order Date" align="center" width="120">
                        <template v-slot="{ row }">
                            <el-tooltip class="item" effect="dark" :content="row.created_at" placement="top-start"
                                        :content-style="{ 'max-height': '60px' }">
                                <div class="ellipsis">{{ row.created_at.substr(0, 10) }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column prop="expired_at" label="Expire Date" align="center" width="120">
                        <template v-slot="{ row }">
                            <el-tooltip class="item" effect="dark" :content="row.expired_at" placement="top-start"
                                        :content-style="{ 'max-height': '60px' }">
                                <div class="ellipsis">{{ row.expired_at.substr(0, 10) }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column prop="reset_time" label="Reset Time" align="center" width="120">
                        <template v-slot="{ row }">
                            <el-tooltip class="item" effect="dark" :content="row.reset_time" placement="top-start"
                                        :content-style="{ 'max-height': '60px' }">
                                <div class="ellipsis">{{ row.reset_time }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column prop="order_id" label="Trans ID" align="center" width="180" sortable></el-table-column>
                    <el-table-column prop="fail_reason" label="Reason" align="center" width="180" sortable></el-table-column>
                    <el-table-column prop="acl_selected" label="Acls" align="center" width="180" sortable>
                    </el-table-column>

                </el-table>
                <div class="pagination-container">
                    <div class="total-orders">Total Orders: {{ total }}</div>
                    <el-pagination 
                        background 
                        layout="->,prev, pager, next" 
                        :total="total"
                        :current-page.sync="currentPage" 
                        @current-change="handleCurrentChange"
                        class="pagination">
                    </el-pagination>
                </div>
            </div>

            <div v-else v-loading="editDetailLoading" class="orderDetail">
                <div class="page-header-wrapper">
                    <el-page-header 
                        @back="goBack" 
                        content="Order Details"
                        class="custom-page-header">
                        <template slot="title">
                            <span class="back-text"><i class="el-icon-arrow-left"></i> Back</span>
                        </template>
                    </el-page-header>
                    <div class="header-actions">
                        <el-button 
                            data-clipboard-action="copy"
                            :data-clipboard-text="editInfo.proxy_list.map(item => `${item.ip}:${item.port}:${item.username}:${item.password}`).join('\n')"
                            @click="handleCpoy" 
                            id="btn"
                            type="primary"
                            class="copy-button">
                            <i class="el-icon-document-copy"></i> Copy Proxy Info
                        </el-button>
                    </div>
                </div>
                <div class="edit-cards-container">
                    <!-- User Info Card -->
                    <el-card class="edit-card" shadow="hover">
                        <div slot="header" class="card-header">
                            <i class="el-icon-user"></i>
                            <span>User Information</span>
                        </div>
                        <div class="card-content">
                            <div class="info-item">
                                <span class="label">Username:</span>
                                <el-tag type="success">{{ editInfo.order.username }}</el-tag>
                            </div>
                            <div class="info-item">
                                <span class="label">Product:</span>
                                <el-tag type="success">{{ editInfo.order.product_name }}</el-tag>
                            </div>
                        </div>
                    </el-card>

                    <!-- Account Management Card -->
                    <el-card class="edit-card" shadow="hover">
                        <div slot="header" class="card-header">
                            <i class="el-icon-setting"></i>
                            <span>Account Management</span>
                        </div>
                        <div class="card-content">
                            <div class="button-group">
                                <el-button type="primary" @click="resetPasswordMethod" :loading="resetpasswordLoading">
                                    <i class="el-icon-key"></i>
                                    Reset Password
                                </el-button>
                                <el-button type="success" @click="checkProxy">
                                    <i class="el-icon-connection"></i>
                                    Check Proxy
                                </el-button>
                            </div>
                        </div>
                    </el-card>

                    <!-- Time Management Card -->
                    <el-card class="edit-card" shadow="hover">
                        <div slot="header" class="card-header">
                            <i class="el-icon-time"></i>
                            <span>Time Management</span>
                        </div>
                        <div class="card-content">
                            <div class="date-picker-group">
                                <el-date-picker
                                    v-model="form.expired_at"
                                    type="datetime"
                                    placeholder="Please select the expire time"
                                    value-format="timestamp"
                                    style="width: 100%; margin-bottom: 15px;">
                                </el-date-picker>
                                <el-button type="primary" @click="editExpiredTime" :loading="resetExpired_at">
                                    <i class="el-icon-timer"></i>
                                    Extend Expire Time
                                </el-button>
                            </div>
                        </div>
                    </el-card>

                    <!-- Proxy & Delivery Card -->
                    <el-card class="edit-card" shadow="hover">
                        <div slot="header" class="card-header">
                            <i class="el-icon-connection"></i>
                            <span>Proxy & Delivery</span>
                        </div>
                        <div class="card-content">
                            <div class="button-group">
                                <el-button type="warning" @click="resetChangeProxy" :loading="ChangeProxyLoading">
                                    <i class="el-icon-refresh"></i>
                                    Change Proxy
                                </el-button>
                                <el-button type="success" @click="deveryOrderAction">
                                    <i class="el-icon-truck"></i>
                                    Delivery
                                </el-button>
                            </div>
                        </div>
                    </el-card>
                </div>
                <el-table :data="editInfo.proxy_list" style="width: 100%" header-cell-class-name="tableDetail_header"
                          show-summary>
                    <el-table-column type="index" align="center" width="80" label="Index"></el-table-column>
                    <el-table-column prop="ip" label="Ip" align="center">
                    </el-table-column>
                    <el-table-column prop="port" label="Port" align="center">
                    </el-table-column>
                    <el-table-column prop="username" label="Username" align="center">
                    </el-table-column>
                    <el-table-column prop="password" label="Password" align="center">
                    </el-table-column>
                    <el-table-column prop="created_at" label="Create Time" align="center" width="120">
                        <template v-slot="{ row }">
                            <el-tooltip class="item" effect="dark" :content="row.created_at" placement="top-start"
                                        :content-style="{ 'max-height': '60px' }">
                                <div class="ellipsis">{{ row.created_at.substr(0, 10) }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column prop="expired_at" label="Expire Time" align="center" width="120">
                        <template v-slot="{ row }">
                            <el-tooltip class="item" effect="dark" :content="row.expired_at" placement="top-start"
                                        :content-style="{ 'max-height': '60px' }">
                                <div class="ellipsis">{{ row.expired_at.substr(0, 10) }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column prop="updated_at" label="Update Time" align="center" width="120">
                        <template v-slot="{ row }">
                            <el-tooltip class="item" effect="dark" :content="row.updated_at" placement="top-start"
                                        :content-style="{ 'max-height': '60px' }">
                                <div class="ellipsis">{{ timeDifference(row.updated_at) }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column prop="status" label="Status" align="center" width="100" sortable>
                        <template v-slot="{ row }">
                            <el-tag v-if="row.status" type="success" style="width: 100%">Available</el-tag>
                            <el-tag v-else type="danger" style="width: 100%">Unavailable</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="bing_delay" label="bing(ms)" align="center" width="100" sortable>
                  </el-table-column>

                  <el-table-column prop="google_delay" label="google(ms)" align="center" width="100" sortable>
                  </el-table-column>

                  <el-table-column prop="amazon_delay" label="amazon(ms)" align="center" width="100" sortable>
                  </el-table-column>
                </el-table>
            </div>
        </el-card>
    </div>
</template>

<script>
import {
    getOrdersList,
    deleteOrder,
    getOrdersDetail,
    resetPassword,
    editTime,
    changeProxy, oneKeyResetProxy, checkProxyFn,deveryOrder
} from "@/api/dashboard/orders"
import ClipboardJS from "clipboard";

export default {
    data() {
        return {
            payMap: {
                0: 'Unpaid',
                1: 'Paid'
            },
            oldMap: {
                0: 'New',
                1: 'Old'
            },
            formInline: {
                search: ''
            },
            reset_order: {
                "order_ids": "",
            },
            tableData: [],
            currentPage: 1,
            total: 0,
            tableLoading: false,
            editVisible: false,
            form: {
                expired_at: ""
            },
            editInfo: {
                order: {
                    username: "",
                    product_name: ""
                },
                proxy_list: []
            },
            editId: null,
            resetpasswordLoading: false,
            resetExpired_at: false,
            ChangeProxyLoading: false,
            searchLoading: false,
            resetLoading: false,
            sortBy: '',
            sortOrder: '',
        }
    },
    mounted() {
        new this.$wow.WOW().init()
        const {params} = this.$route
        params.username && (this.formInline.search = params.username)
        this.getTableData()
    },
    methods: {
        tableRowClassName({row}) {
            return row.delivery_num === 0 && row.pay_status === 1 ? 'background-color: red; color:white;' : ''
        },
        async onSubmit() {
            this.searchLoading = true;
            try {
                await this.getTableData();
                this.$message.success('Search completed');
            } catch (error) {
                this.$message.error('Search failed: ' + error.message);
            } finally {
                this.searchLoading = false;
            }
        },
        async oneKeyReset() {
            this.$confirm('Are you sure to reset these orders?', 'Reset These Orders', {
                confirmButtonText: 'YES',
                cancelButtonText: 'NO',
                type: 'warning'
            }).then(() => {
                oneKeyResetProxy(this.reset_order, (res) => {
                    this.$message.success(res.msg)
                }, (res) => {
                    this.$message.warning(res.msg)
                })

            }).catch(() => {
            });
        },
        timeDifference(previous) {
            // 获取当前时间戳 utc时间
            const currentUTCTime = new Date().getTime();
            previous = new Date(previous + 'Z').getTime();
            const msPerMinute = 60 * 1000;
            const elapsed = currentUTCTime - previous;

            if (elapsed < msPerMinute) {
                return Math.round(elapsed/1000) + ' seconds ago';
            }

            else if (elapsed < msPerMinute * 60) {
                return Math.round(elapsed/msPerMinute) + ' minutes ago';
            }

            else if (elapsed < msPerMinute * 60 * 24) {
                return Math.round(elapsed/(msPerMinute * 60)) + ' hours ago';
            }

            else if (elapsed < msPerMinute * 60 * 24 * 30) {
                return Math.round(elapsed/(msPerMinute * 60 * 24)) + ' days ago';
            }

            else if (elapsed < msPerMinute * 60 * 24 * 30 * 12) {
                return Math.round(elapsed/(msPerMinute * 60 * 24 * 30)) + ' months ago';
            }

            else {
                return Math.round(elapsed/(msPerMinute * 60 * 24 * 30 * 12)) + ' years ago';
            }
        },
        handleCurrentChange(val) {
            this.getTableData()
        },
        getTableData() {
            const offset = (this.currentPage - 1) * 10
            const param = {search: this.formInline.search, limit: 10, offset}
            this.tableLoading = true
            getOrdersList(param, (res) => {
                this.tableData = res.data.data
                this.total = res.data.total
                this.tableLoading = false
            }, (res) => {
                this.tableLoading = false
                this.tableData = []
                this.total = 0
                this.$message.warning(res.msg)
            })
        },


        // 删除用户
        deleteOrder(row, index) {
            this.$confirm(`UserName: ${row.username}`, 'Delete Order ?', {
                confirmButtonText: 'YES',
                cancelButtonText: 'NO',
                type: 'warning'
            }).then(() => {
                deleteOrder({id: row.id}, (res) => {
                    this.currentPage = this.currentPage === 1 ? this.currentPage : (this.tableData.length == 1 && index === 0) ? this.currentPage - 1 : this.currentPage
                    this.getTableData()
                })
            }).catch(() => {

            })
        },
        // 修改用户
        editOrder(row) {
            this.editVisible = !this.editVisible
            this.editDetailLoading = true
            this.editId = row.id
            getOrdersDetail({id: row.id}, (res) => {
                this.editDetailLoading = false
                this.$set(this, 'editInfo', res.data)
            }, (res) => {
                this.editVisible = !this.editVisible
                this.$message.warning(res.msg)
            })
        },
        //修改密码
        resetPassword() {
            return new Promise((resolve, reject) => {
                this.resetpasswordLoading = true;
                resetPasswordApi({id: this.form.id}, (res) => {
                    this.resetpasswordLoading = false;
                    this.$message.success("success");
                    resolve();
                }, (error) => {
                    this.resetpasswordLoading = false;
                    reject(error);
                });
            });
        },
        goBack() {
            this.editVisible = !this.editVisible
            this.getTableData()
            this.form.expired_at = ""
            this.$set(this, "editInfo", {
                order: {
                    username: "",
                    product_name: ""
                },
                proxy_list: []
            })
        },
        resetPasswordMethod() {
            this.resetpasswordLoading = !this.resetpasswordLoading
            resetPassword({id: this.editId}, res => {
                this.resetpasswordLoading = !this.resetpasswordLoading
                this.$message.success("Successful operation")
            }, (res) => {
                this.resetpasswordLoading = !this.resetpasswordLoading
                this.$message.error(res.msg)
            })
        },
        editExpiredTime() {
            if (!this.form.expired_at) return this.$message.warning("Please select the expire time")
            this.resetExpired_at = !this.resetExpired_at
            editTime({id: this.editId, expired_at: this.form.expired_at}, res => {
                this.resetExpired_at = !this.resetExpired_at
                this.$message.success("Successful operation")
            }, (res) => {
                this.resetExpired_at = !this.resetExpired_at
                this.$message.error(res.msg)
            })
        },
        resetChangeProxy() {
            this.ChangeProxyLoading = !this.ChangeProxyLoading
            changeProxy({id: this.editId}, res => {
                this.ChangeProxyLoading = !this.ChangeProxyLoading
                this.$message.success(res.msg)
            }, (res) => {
                this.ChangeProxyLoading = !this.ChangeProxyLoading
                this.$message.error(res.msg)
            })
        },
        checkProxy() {
            checkProxyFn({id: this.editId}, res => {
                    this.$message.success(res.msg)
                }, (res) => {
                    this.$message.error(res.msg)
                }
            )
        },
        deveryOrderAction() {
            deveryOrder({id: this.editId}, res => {
                    this.$message.success(res.msg)
                }, (res) => {
                    this.$message.error(res.msg)
                }
            )
        },
        handleCpoy() {
            const clipboard = new ClipboardJS('#btn')
            clipboard.on('success', (e) => {

                e.clearSelection()
                // 释放内存
                clipboard.destroy()
                this.$message.success("Successful replication")
            })
            // 复制失败
            clipboard.on('error', (e) => {
                clipboard.destroy()
                this.$message.error("Replication failure")
            })
        },

        handleSortChange({ prop, order }) {
            this.sortBy = prop;
            this.sortOrder = order;
            this.getTableData();
        },
        handleCommand(command, row, index) {
            switch (command) {
                case 'edit':
                    this.editOrder(row);
                    break;
                case 'delete':
                    this.$confirm('Are you sure to delete this order?', 'Warning', {
                        confirmButtonText: 'Confirm',
                        cancelButtonText: 'Cancel',
                        type: 'warning'
                    }).then(() => {
                        this.deleteOrder(row, index);
                    }).catch(() => {
                        this.$message.info('Delete cancelled');
                    });
                    break;
            }
        },
    },
    watch: {
        $route: {
            handler() {
                const {params} = this.$route
                params.username && (this.formInline.search = params.username)
                this.getTableData()
            },
            deep: true
        }
    }

}

</script>

<style lang="scss" scoped>
.box-card {
    margin: 20px;
    
    .search-card {
        margin-bottom: 20px;
        border-radius: 8px;
        background: linear-gradient(135deg, #ff758c 0%, #ff7eb3 100%) !important;
        
        .search-form, .reset-form {
            padding: 15px;
            .el-form-item {
                margin-bottom: 0;
                margin-right: 15px;
            }
            .el-input {
                width: 300px;
                .el-input__inner {
                    border-radius: 20px;
                    &:focus {
                        border-color: #ff9a9e;
                    }
                }
            }
        }
    }

    :deep(.table_header) {
        background: linear-gradient(135deg, #f5f7fa 0%, #e4e7ed 100%);
        color: #303133;
        font-weight: 600;
        padding: 12px 0;
    }

    .el-button {
        border-radius: 20px;
        padding: 10px 20px;
        transition: all 0.3s ease;
        
        &.el-button--info {
            background: #606266;
            border-color: #606266;
            color: white;
            &:hover, &:focus {
                background: #909399;
                border-color: #909399;
                transform: translateY(-1px);
                box-shadow: 0 2px 8px rgba(144, 147, 153, 0.3);
            }
        }
        
        &.el-button--primary {
            background: #ff758c;
            border-color: #ff758c;
            &:hover, &:focus {
                background: #ff8fa3;
                border-color: #ff8fa3;
                transform: translateY(-1px);
                box-shadow: 0 2px 8px rgba(255, 117, 140, 0.3);
            }
        }
        
        &.el-button--warning {
            background: #ffd3b6;
            border-color: #ffd3b6;
            color: #ffffff;
            &:hover, &:focus {
                background: #ffddc6;
                border-color: #ffddc6;
                transform: translateY(-1px);
                box-shadow: 0 2px 8px rgba(255, 211, 182, 0.3);
            }
        }
        
        &.el-button--danger {
            background: #ff9a9e;
            border-color: #ff9a9e;
            &:hover, &:focus {
                background: #ffb5b8;
                border-color: #ffb5b8;
                transform: translateY(-1px);
                box-shadow: 0 2px 8px rgba(255, 154, 158, 0.3);
            }
        }

        &.el-button--small {
            padding: 8px 15px;
        }

        [class^="el-icon-"] {
            margin-right: 5px;
        }
    }

    .el-button-group {
        .el-button {
            margin: 0;
            &:first-child {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
            &:last-child {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
    }

    .el-dropdown {
        .el-button {
            width: 90px;
            display: flex;
            align-items: center;
            justify-content: center;
            
            i {
                margin-left: 5px;
            }
        }
    }

    .el-dropdown-menu {
        padding: 5px 0;
        
        .el-dropdown-item {
            display: flex;
            align-items: center;
            padding: 8px 16px;
            font-size: 14px;
            
            i {
                margin-right: 8px;
                font-size: 16px;
            }
            
            &.el-dropdown-item--divided {
                border-top: 1px solid #ebeef5;
                margin-top: 5px;
                padding-top: 10px;
            }
            
            &:hover {
                background-color: #fff3f4;
                color: #ff758c;
                
                i {
                    color: #ff758c;
                }
            }
        }
    }

    .el-tag {
        border-radius: 12px;
        padding: 0 12px;
        height: 24px;
        line-height: 22px;
        
        &.el-tag--success {
            background-color: #95e1d3;
            border-color: #95e1d3;
            color: #ffffff;
        }
        
        &.el-tag--danger {
            background-color: #ff9a9e;
            border-color: #ff9a9e;
            color: #ffffff;
        }
    }

    .pagination-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
        padding: 10px 0;

        .total-orders {
            color: #606266;
            font-size: 14px;
        }

        .el-pagination {
            .el-pager li {
                border-radius: 4px;
                &.active {
                    background-color: #ff758c;
                }
            }
            .btn-next, .btn-prev {
                border-radius: 4px;
            }
        }
    }
}

.edit-cards-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    padding: 20px;
}

.edit-card {
    transition: all 0.3s ease;
    
    &:hover {
        transform: translateY(-5px);
    }

    .card-header {
        display: flex;
        align-items: center;
        font-weight: bold;
        
        i {
            margin-right: 8px;
            color: #409EFF;
        }
    }

    .card-content {
        padding: 10px 0;

        .info-item {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            
            &:last-child {
                margin-bottom: 0;
            }

            .label {
                min-width: 80px;
                color: #909399;
                margin-right: 10px;
            }
        }

        .button-group {
            display: flex;
            gap: 10px;
            flex-wrap: wrap;

            .el-button {
                flex: 1;
                min-width: 140px;
                
                i {
                    margin-right: 5px;
                }
            }
        }

        .date-picker-group {
            .el-button {
                width: 100%;
            }
        }
    }
}

.orderDetail {
    padding: 20px;
}

.expand-card {
    margin: 0 10px;
    background-color: #fafafa;
    border: none;
    
    .expand-content {
        display: flex;
        gap: 15px;
        padding: 5px;
        
        .expand-section {
            flex: 1;
            background-color: white;
            border-radius: 6px;
            padding: 12px;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
            
            .section-title {
                font-size: 14px;
                font-weight: 500;
                color: #303133;
                margin-bottom: 10px;
                display: flex;
                align-items: center;
                
                i {
                    margin-right: 6px;
                    color: #ff758c;
                    font-size: 16px;
                }
            }
            
            .section-content {
                .scroll-container {
                    margin-bottom: 10px;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    .el-scrollbar__wrap {
                        overflow-x: auto;
                        overflow-y: hidden;
                        margin-bottom: -17px !important;  
                        margin-right: -17px !important;   
                    }
                    
                    .el-scrollbar__bar.is-horizontal {
                        height: 4px;
                        opacity: 0;
                        transition: opacity 0.3s;
                        position: absolute;
                        bottom: 2px;
                        left: 0;
                        right: 0;
                        z-index: 1;
                        
                        .el-scrollbar__thumb {
                            background-color: #909399;
                            opacity: 0.3;
                            
                            &:hover {
                                opacity: 0.5;
                            }
                        }
                    }
                    
                    .el-scrollbar__bar.is-vertical {
                        display: none;
                    }

                    &:hover {
                        .el-scrollbar__bar.is-horizontal {
                            opacity: 1;
                        }
                    }

                    .scrollable-row {
                        min-width: 800px;
                        padding-bottom: 6px;  
                    }
                }

                .info-row {
                    display: flex;
                    position: relative;  
                    
                    .info-item {
                        flex: 1;
                        display: flex;
                        align-items: center;
                        padding: 0 10px;
                        
                        .label {
                            min-width: 60px;
                            color: #909399;
                            font-size: 13px;
                        }
                        
                        .value {
                            color: #303133;
                            font-size: 13px;
                            margin-left: 4px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                        
                        .el-tag {
                            font-size: 12px;
                            height: 20px;
                            line-height: 18px;
                            padding: 0 6px;
                        }
                    }
                }
            }
        }
    }
}

.page-header-wrapper {
    background: linear-gradient(135deg, #f5f7fa 0%, #e4e7ed 100%);
    padding: 16px 24px;
    border-radius: 8px;
    margin-bottom: 20px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05);
    display: flex;
    justify-content: space-between;
    align-items: center;

    .custom-page-header {
        background: transparent;
        padding: 0;
        
        :deep(.el-page-header__left) {
            .el-icon-back {
                display: none;
            }
            
            .el-page-header__title {
                .back-text {
                    font-size: 14px;
                    color: #409EFF;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    transition: all 0.3s;
                    
                    &:hover {
                        color: #66b1ff;
                        transform: translateX(-3px);
                    }
                    
                    i {
                        margin-right: 5px;
                    }
                }
            }
        }
        
        :deep(.el-page-header__content) {
            font-size: 18px;
            font-weight: 600;
            color: #303133;
        }
    }

    .header-actions {
        .copy-button {
            background: #409EFF;
            border-color: #409EFF;
            padding: 8px 16px;
            transition: all 0.3s;
            
            &:hover {
                background: #66b1ff;
                border-color: #66b1ff;
                transform: translateY(-2px);
                box-shadow: 0 2px 8px rgba(64, 158, 255, 0.3);
            }
            
            i {
                margin-right: 5px;
            }
        }
    }
}
</style>