<template>
  <div class="body-container">
    <div class="load" v-show="showLoading">
      <img src="@/assets/images/mentos.gif" alt="" />
    </div>
    <div class="layout">
      <header-com :index="1" :scrollTop="scrollTop"></header-com>
      <div class="fproduct">
        <div class="wrap">
          <div
            class="fpitl wow fadeInUp"
            data-wow-delay="0.2s"
            data-wow-duration="1s"
          >
            <h3>Pick your plans here！</h3>
            <i></i>
          </div>
          <div
            class="falltl wow fadeInUp"
            data-wow-delay="0.3s"
            data-wow-duration="1s"
            v-if="!isLable"
          >
            <ul class="clearfix">
              <li
                v-for="(item, index) in tapList"
                :key="index"
                @click="chooseProductIndex(index)"
                :class="chooseIndex == index ? 'on' : ''"
              >
                {{ item.collection_name }}
              </li>
            </ul>
          </div>
          <div class="fneonsw">
            <div
              class="fneonsw_slide"
              v-for="(item, index) in proxyList"
              :key="index"
            >
              <div @click="openShowPayInfo(item)">
                <div class="ico">
                  <img
                    v-if="index % 3 === 0"
                    style="width: 87px; height: 87px"
                    src="../assets/images/fico15.png"
                    alt=""
                  />
                  <img
                    v-else-if="index % 3 === 1"
                    style="width: 87px; height: 87px"
                    src="../assets/images/fico16.png"
                    alt=""
                  />
                  <img
                    v-else
                    style="width: 87px; height: 87px"
                    src="../assets/images/fico17.png"
                    alt=""
                  />
                </div>
                <div class="text">
                  <h2>{{ item.product_name }}</h2>
                  <i
                    class="pro-show-ellipsis"
                    style="font-size: large; color: #d6227a"
                    >{{ item.lower_price.slice(0, -1) }}/IP</i
                  >
                  <p class="pro-show-ellipsis" style="white-space: pre-line">
                    {{ item.product_desc }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            class="falltext wow fadeInUp"
            data-wow-delay="0.3s"
            data-wow-duration="1s"
          >
            ALL SALES ARE FINAL AND WE DO NOT OFFER REFUNDS.We do not change or
            edit any orders once payment has been processed. Please read all
            descriptions carefully prior to ordering. We can only guarantee that
            the IPs in question work and connect to the Internet in general and
            the above mentioned are on a best case efforts as working sites are
            always changing.
          </div>
        </div>
      </div>
    </div>

    <!-- 弹窗 -->
    <div
      class="fcokebj"
      v-show="showPayInfo || showPayBox || showPayCode"
      @click="closePay"
      style="z-index: 9999"
    ></div>
    <div class="fcokeproxy fproxys" v-show="showPayInfo" style="z-index: 10999">
      <h2>{{ productDel.title }}</h2>
      <div class="fcha" @click="closePay"></div>
      <div class="list">
        <ul>
          <li>
            <!-- <input type="number" class="confirm_input" v-model="productNum"/> -->
            console.log(productDel.cart_step);
            <el-input-number
              v-model="productNumCalculated"
              :min="productDel.cart_step"
              label="num"
              :step="productDel.cart_step"
              :max="
                this.selectResult ? this.selectResult.stock : productDel.storage
              "
              :step-strictly="true"
              @change="handleNumChange"
            ></el-input-number>
            <h3></h3>
            <h6>Quantity</h6>
          </li>
          <li>
            <h3>
              <i>{{
                this.selectResult ? this.selectResult.stock : productDel.storage
              }}</i>
            </h3>
            <h6>Storage</h6>
          </li>
          <li>
            <h3>
              <i class="ellipsis-2">{{ productDel.location }}</i>
            </h3>
            <h6>Location</h6>
          </li>
        </ul>
      </div>
      <div
        class="text"
        v-html="`${productDel.product_desc}`.replace(/\n/g, '<br/>')"
      ></div>
      <div class="option-card">
        <div
          class="item"
          v-for="(item, index) in productDel.variant_options"
          :key="index"
        >
          <div
            class="ellipsis"
            v-if="
              !(
                productDel.variant_options.length == 1 &&
                item.option_values.length < 1
              )
            "
          >
            {{ item.option_name }}
          </div>
          <!-- sitem.option_name != 'acl_count' -->
          <el-radio
            v-for="(sitem, sid) in item.option_values"
            :key="sid"
            v-if="
              !(
                productDel.variant_options.length == 1 &&
                item.option_values.length < 1
              )
            "
            :disabled="isChosed"
            @input="buttonChange(sid, index)"
            v-model="productDel.variant_options[index].radio"
            :label="sid + ''"
            border
          >
            {{ sitem.option_value }}
          </el-radio>
        </div>
      </div>
      <div
        class="fmoney clearfix"
        style="display: flex; justify-content: space-between"
      >
        <h3>${{ totalPrice.toFixed(2) }}</h3>
        <div
          class="purchase"
          style="cursor: pointer; background-color: #e6a23c"
          @click="choseAcl"
        >
          Modularization
        </div>
        <a
          href="javascript: ;"
          :class="
            (
              !this.selectResult
                ? productDel.storage - productNum >= 0 &&
                  productDel.storage > 0 &&
                  is_active
                : selectResult.stock - productNum >= 0 &&
                  selectResult.stock > 0 &&
                  is_active
            )
              ? 'purchase'
              : 'purchase grey'
          "
          @click="openShowPayBox"
          >Purchase</a
        >
      </div>
    </div>

    <!-- 内部弹窗 -->
    <div
      class="fcokebj"
      v-show="choseAclShow"
      @click="choseAclShow = false"
      style="z-index: 11000"
    ></div>
    <div
      class="fcokeproxy fproxys"
      v-show="choseAclShow"
      style="z-index: 11001; width: 800px; background-color: #95d2f1"
      v-loading="choseAclShowLoading"
    >
      <div class="fcha" @click="choseAclShow = false"></div>
      <h3
        style="
          text-align: center;
          color: #fff;
          font-family: 'Pang';
          font-size: 20px;
          line-height: 50px;
        "
      >
        Modularization
      </h3>
      <el-card
        class="box-card"
        style="background-color: #e1f3d8"
        v-loading="showLoadingChoseAclShow"
      >
        <div
          style="
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            min-height: 200px;
            max-height: 400px;
            overflow-y: auto;
          "
        >
          <div
            style="
              width: 23%;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
            "
            v-for="(item, index) in productsStock"
            :key="index"
          >
            <div
              :class="item.isSelect ? 'select-acl-selected' : 'select-acl'"
              @click="selectAcl(item, index)"
              style="cursor: pointer"
            >
              {{ item.acl_name }}
            </div>
            <div
              style="
                font-family: 'Pang';
                font-size: 14px;
                line-height: 30px;
                width: 100%;
                text-align: center;
              "
            >
              Stock:{{ item.stock }}
            </div>
            <div
              style="
                font-family: 'Pang';
                font-size: 14px;
                line-height: 30px;
                width: 100%;
                text-align: center;
              "
            >
              Price:+${{ item.price }}
            </div>
            <div
              class="view-domains-btn"
              style="
                font-family: 'Pang';
                font-size: 14px;
                line-height: 30px;
                width: 100%;
                text-align: center;
              "
              @click="showDomainList(item)"
            >
              View Domains
            </div>
          </div>
        </div>
      </el-card>
      <div
        class="fmoney clearfix"
        style="display: flex; justify-content: center"
      >
        <div
          :class="
            (
              productsStock.length
                ? productsStock.every((item) => !item.isSelect)
                : true
            )
              ? 'purchase grey'
              : 'purchase'
          "
          @click="confirmSelectAcl"
          style="cursor: pointer"
        >
          Confirm
        </div>
      </div>
    </div>
    <el-dialog
      :title="null"
      :visible.sync="domainListVisible"
      width="460px"
      custom-class="domain-dialog"
      :modal-append-to-body="true"
      append-to-body
      :z-index="20000"
    >
      <div class="domain-list-container">
        <div class="domain-header">
          <div class="title-section">
            <i class="el-icon-connection"></i>
            <span class="main-title">Available Domains</span>
          </div>
          <div class="close-btn" @click="domainListVisible = false">
            <i class="el-icon-close"></i>
          </div>
        </div>
        <div class="domain-list">
          <div v-if="selectedDomains && selectedDomains.length > 0">
            <div v-for="(domain, index) in selectedDomains" :key="index" class="domain-item">
              <i class="el-icon-link"></i>
              <span class="domain-text">{{ domain }}</span>
            </div>
          </div>
          <div v-else class="no-domains">
            <i class="el-icon-warning-outline"></i>
            <span>No domains available</span>
          </div>
        </div>
      </div>
    </el-dialog>
    <div class="fcokepay fproxys" v-show="showPayBox">
      <div class="log pay_logo">
        <img src="@/assets/images/logo.png" alt="" />
      </div>
      <div class="fcha" @click="closePay"></div>
      <div class="coke">
        <h2>Order Details</h2>
        <div class="order_detail_text">
          <span>{{ productDel.title }}</span>
          <span>${{ productDel.price }}</span>
        </div>
        <div class="order_detail_coupon">
          <span>Coupon</span>
          <select>
            <option
              value=""
              disabled
              selected
              style="display: none"
              class="place_coupon"
            >
              Why not choose coupons?
            </option>
            <option value="0">1</option>
            <option value="1">2</option>
          </select>
        </div>
        <div class="order_detail_total">
          <span>ToTal</span>
          <span>${{ totalPrice.toFixed(2) }}</span>
        </div>
        <div class="choose_pay_type">
          <div class="pay_type_item">
            <img src="@/assets/images/order_choose_1.png" alt="" />
            <span>Wechat Pay</span>
          </div>
          <div class="pay_type_item">
            <img src="@/assets/images/order_choose_2.png" alt="" />
            <span>Ali Pay</span>
          </div>
          <div class="pay_type_item">
            <img src="@/assets/images/order_choose_2.png" alt="" />
            <span>Stripe</span>
          </div>
        </div>
        <div class="pay_type_code">
          <img :src="payCodeImage" alt="" />
          <span>（请备注订单号）</span>
        </div>
      </div>
      <div class="pay" @click="checkOutOrder">Check Out</div>
    </div>
    <div>
      <el-dialog
        title="Payment"
        :visible.sync="dialogVisible"
        width="400px"
        center
        :before-close="handleClose"
      >
        <div v-if="remainingTime > 0" class="remaining-time">
          Remaining time: {{ minutes }} minutes {{ seconds }} seconds
          <br />
          Please confirm that your account email is the same as your payment
          email, and if it does not match, shipment will not be made.
        </div>
        <div v-else class="timeout">
          Payment timeout, please place a new order.
        </div>
        <el-button
          type="primary"
          class="goto-dashboard"
          :disabled="remainingTime > 1200000"
          @click="gotoDashboard"
        >
          {{
            remainingTime > 1200000 ? "In progress" : "Paid, go to dashboard"
          }}
        </el-button>
      </el-dialog>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { swiper, swiperSlide } from "vue-awesome-swiper";
import { Dialog } from 'element-ui';
import "swiper/dist/css/swiper.css";
import headerCom from "@/components/header";
import {
  productListAll,
  labelProduct,
  createOrder,
  tapList,
  checkOrderStatus,
  getProductsStock,
  getProductsVariantInfo,
} from "@/api/index";

export default {
  name: "Product",
  components: {
    swiper,
    swiperSlide,
    [Dialog.name]: Dialog,
    headerCom
  },
  computed: {
    productNumCalculated: {
      get() {
        return this.productNum;
      },
      set(value) {
        this.productNum = value;
      },
    },
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
    minutes() {
      return Math.floor(this.remainingTime / 60000);
    },
    seconds() {
      return Math.floor((this.remainingTime % 60000) / 1000);
    },
  },
  data() {
    return {
      selectResult: null,
      choseAclShowLoading: false,
      isChosed: false,
      productsStock: [],
      showLoadingChoseAclShow: true,
      choseAclShow: false,
      canJumpToDashboard: true,
      remainingTime: 1800000, // 剩余时间，单位毫秒
      intervalId: null, // 定时器 ID
      dialogVisible: false,
      swiperOption: {
        loop: false, // 是否循环轮播
        autoplay: false, // 是否可以自动轮播
        slidesPerView: 3, // 可是区域内可展示多少个块
        spaceBetween: 40, // 块之间间隔距离
        initialSlide: 0, // 默认初始显示块
        freeMode: false,
        // 显示分页
        pagination: {
          el: ".fneonswiper .fspan",
          clickable: true,
        },
        // 设置点击箭头
        navigation: {
          nextEl: ".fneonswiper .fnext",
          prevEl: ".fneonswiper .fprod",
        },
      },
      showPayInfo: false, //付款详情
      showPayBox: false, //付款弹窗
      showPayCode: false, // 付款二维码弹窗
      productListData: [], //产品列表
      chooseIndex: 0, //分类索引
      productDel: {},

      isLable: false, // 是标签产品还是普通进入产品
      payCodeImage: "",
      scrollTop: 0,
      tapList: [],
      proxyList: [],
      showLoading: false,
      productNum: null,
      is_active: false,
      cartStep: 8,
      totalPrice: 0,
      domainListVisible: false,
      selectedDomains: [],
      currentDomains: [
        'netflix.com',
        'hulu.com',
        'disneyplus.com',
        'amazon.com',
        'hbomax.com',
        'youtube.com',
        'spotify.com',
        'apple.com',
        'facebook.com',
        'instagram.com'
      ],
    };
  },
  updated() {},

  watch: {
    // 监听路由是否变化
    // '$route' (to, from) {
    // 	if(to.query.id !== from.query.id){
    // 		this.getLabelProduct(to.query.id)
    // 		this.isLable = true
    // 	}
    // 	if(to.query.index !== from.query.index){
    // 		this.chooseIndex = to.query.index
    // 		this.proxyList = this.productListData[to.query.index].proxyList
    // 	}
    // }
  },
  created() {
    this.getTapList();
  },
  mounted() {
    new this.$wow.WOW().init();
    //   if (this.$route.query.id){
    // 	this.getLabelProduct(this.$route.query.id)
    // 	this.isLable = true
    //   }else{
    // 	if (this.$route.query.index){
    // 		this.chooseIndex = this.$route.query.index
    // 	}

    //    this.isLable = false
    //   }

    window.addEventListener("scroll", this.handleScroll);
  },

  methods: {
    // 提交选择的acl信息 然后设置 radio为禁用状态
    confirmSelectAcl() {
      if (
        this.productsStock.length
          ? this.productsStock.every((item) => !item.isSelect)
          : true
      ) {
        this.$message.warning("Please select");
        return;
      }
      // this.productsStock
      this.choseAclShowLoading = true;
      let option1List = [];
      this.productsStock.forEach((ele, index) => {
        if (ele.isSelect) {
          option1List.push(ele.acl_id);
        }
      });
      let num1, num2, num, option1, option2, option3;
      if (this.productDel.variant_options.length == 1) {
        num1 = Number(this.productDel.variant_options[0].radio);
        option1 =
          this.productDel.variant_options[0].option_values[num1].option_value;
        option2 = "";
        option3 = "";
      } else if (this.productDel.variant_options.length == 2) {
        num1 = Number(this.productDel.variant_options[0].radio);
        num2 = Number(this.productDel.variant_options[1].radio);
        option1 =
          this.productDel.variant_options[0].option_values[num1].option_value;
        option2 =
          this.productDel.variant_options[1].option_values[num2].option_value;
        option3 = "";
      } else if (this.productDel.variant_options.length == 3) {
        num1 = Number(this.productDel.variant_options[0].radio);
        num2 = Number(this.productDel.variant_options[1].radio);
        num = Number(this.productDel.variant_options[2].radio);
        option1 =
          this.productDel.variant_options[0].option_values[num1].option_value;
        option2 =
          this.productDel.variant_options[1].option_values[num2].option_value;
        option3 =
          this.productDel.variant_options[2].option_values[num].option_value;
      }
      let params = {
        product_id: this.productDel.id,
        option_selected: {
          option1: option1,
          option2: option2,
          option3: option3,
          acl_selected: option1List,
        },
        // option_selected:[,this.productDel.variant_options[0].option_values[num1].option_value,this.productDel.variant_options[1].option_values[num2].option_value]
        // option1:
        // option2:
        // option3:
      };
      getProductsVariantInfo(
        params,
        (res) => {
          this.selectResult = res.data ? res.data : null;
          this.totalPrice = this.selectResult.price * this.productNum;
          this.choseAclShow = false;
          this.isChosed = true;
          this.$nextTick(() => {
            this.choseAclShowLoading = false;
          });
        },
        (err) => {
          console.log(err);
        }
      );
    },
    // this.isChosed
    selectAcl(item, index) {
      // 勾选
      if (this.productsStock[index].isSelect) {
        this.productsStock[index].isSelect = false;
      } else {
        this.productsStock[index].isSelect = true;
        // 显示域名列表
        console.log('Selected item:', item); // 调试用
        this.selectedDomains = item.domains || this.currentDomains; // 临时测试数据
        this.domainListVisible = true;
      }
      this.$forceUpdate();
    },
    choseAcl() {
      this.choseAclShow = true;
      this.showLoadingChoseAclShow = true;
      let num1, num2, num, option1, option2, option3;
      if (
        this.productDel.variant_options.length == 1 &&
        this.productDel.variant_options[0].option_values.length > 0
      ) {
        num1 = Number(this.productDel.variant_options[0].radio);
        option1 =
          this.productDel.variant_options[0].option_values[num1].option_value;
        option2 = "";
        option3 = "";
      } else if (this.productDel.variant_options.length == 2) {
        num1 = Number(this.productDel.variant_options[0].radio);
        num2 = Number(this.productDel.variant_options[1].radio);
        option1 =
          this.productDel.variant_options[0].option_values[num1].option_value;
        option2 =
          this.productDel.variant_options[1].option_values[num2].option_value;
        option3 = "";
      } else if (this.productDel.variant_options.length == 3) {
        num1 = Number(this.productDel.variant_options[0].radio);
        num2 = Number(this.productDel.variant_options[1].radio);
        num = Number(this.productDel.variant_options[2].radio);
        option1 =
          this.productDel.variant_options[0].option_values[num1].option_value;
        option2 =
          this.productDel.variant_options[1].option_values[num2].option_value;
        option3 =
          this.productDel.variant_options[2].option_values[num].option_value;
      } else {
        option1 = "";
        option2 = "";
        option3 = "";
      }
      let params = {
        product_id: this.productDel.id,
        option_selected: {
          option1: option1,
          option2: option2,
          option3: option3,
          acl_selected: [],
        },
      };
      getProductsStock(
        params,
        (res) => {
          this.productsStock = res.data ? res.data : [];
          setTimeout(() => {
            this.showLoadingChoseAclShow = false;
          }, 500);
          // this.getProductList()
        },
        (err) => {
          console.log(err);
        }
      );
    },
    showDomainList(item) {
      // 从 selectResult 中获取 domains
      this.selectedDomains = item?.domains || [];
      this.domainListVisible = true;
    },
    handleNumChange(value) {
      this.totalPrice = this.selectResult
        ? this.selectResult.price * value
        : this.productDel.variant_price * value;
      // 判断当前是否是已经选过产品
      if (this.selectResult) {
        if (value > this.selectResult.storage) {
          this.productNum = this.selectResult.storage;
          this.totalPrice = this.selectResult.price * this.productNum
        } else {
          this.productNum = value;
          this.totalPrice = this.selectResult.price * this.productNum
        }
      } else {
        if (value > this.productDel.storage) {
          this.productNum = this.productDel.storage;
          this.totalPrice = this.productDel.variant_price * this.productNum;
        } else {
          this.productNum = value;
          this.totalPrice = this.productDel.variant_price * this.productNum;
        }
      }
    },
    //打开付款详情
    openShowPayInfo(item) {
      this.showPayInfo = true;
      this.isChosed = false;
      this.selectResult = null;
      this.productDel = JSON.parse(JSON.stringify(item));
      this.productDel.location = item.variants[0].variant_desc;
      this.productNum = item.variants[0].cart_step;
      this.is_active = item.variants[0].is_active;
      this.productDel.storage = item.variants[0].variant_stock;
      this.productDel.cart_step = item.variants[0].cart_step;
      this.productDel.variant_price = item.variants[0].variant_price;
      this.productDel.variant_option1 = item.variants[0].variant_option1;
      this.productDel.variant_option2 = item.variants[0].variant_option2;
      this.totalPrice = this.productDel.variant_price * this.productNum;

      this.productDel.variant_options.forEach((ele, index) => {
        this.productDel.variant_options[index].radio = "0";
      });
    },
    // 打开付款页面
    openShowPayBox() {
      // (!this.selectResult?(((productDel.storage-productNum) >= 0&&productDel.storage>0&&is_active)):(((selectResult.stock-productNum) >= 0&&selectResult.stock>0&&is_active)))
      if (this.selectResult) {
        if (
          this.selectResult.stock - this.productNum >= 0 &&
          this.selectResult.stock > 0 &&
          this.is_active
        ) {
          this.showPayBox = false;
        } else {
          this.$message.warning("Out of stock");
          return;
        }
        if (this.productNum - this.selectResult.stock > 0) {
          return;
        }
      } else {
        this.choseAcl();
        // this.$message.warning('please select ACL')
        return;
        // if((this.productDel.storage-this.productNum) >= 0 && this.productDel.storage>0 && this.is_active) {
        //     this.showPayBox = false
        // } else {
        //     this.$message.warning('Out of stock')
        //     return
        // }
        // if ((this.productNum - this.productDel.storage) > 0) {
        //     return
        // }
      }
      // if ((this.productDel.storage - this.productNum) >= 0 && this.productDel.storage > 0 && this.is_active) {
      //     this.showPayBox = false
      // } else {
      //     this.$message.warning('Out of stock');
      //     return
      // }

      // if(this.productsStock.length?(this.productsStock.every(item=>!item.isSelect)):true) {
      //     this.$message.warning('Please select ACL');
      //     return
      // }

      let option1List = [];
      this.productsStock.forEach((ele, index) => {
        if (ele.isSelect) {
          option1List.push(ele.acl_id);
        }
      });
      let num1, num2, num, option1, option2, option3, params;
      if (this.productDel.variant_options.length == 1) {
        num1 = Number(this.productDel.variant_options[0].radio);
        option1 =
          this.productDel.variant_options[0].option_values[num1].option_value;
        option2 = "";
        option3 = "";
      } else if (this.productDel.variant_options.length == 2) {
        num1 = Number(this.productDel.variant_options[0].radio);
        num2 = Number(this.productDel.variant_options[1].radio);
        option1 =
          this.productDel.variant_options[0].option_values[num1].option_value;
        option2 =
          this.productDel.variant_options[1].option_values[num2].option_value;
        option3 = "";
      } else if (this.productDel.variant_options.length == 3) {
        num1 = Number(this.productDel.variant_options[0].radio);
        num2 = Number(this.productDel.variant_options[1].radio);
        num = Number(this.productDel.variant_options[2].radio);
        option1 =
          this.productDel.variant_options[0].option_values[num1].option_value;
        option2 =
          this.productDel.variant_options[1].option_values[num2].option_value;
        option3 =
          this.productDel.variant_options[2].option_values[num].option_value;
      }
      // let num1,num2
      // num1 = Number(this.productDel.variant_options[0].radio)
      // num2 = Number(this.productDel.variant_options[1].radio)
      params = {
        product_id: this.productDel.id,
        quantity: this.productNum,
        option_selected: {
          option1: option1,
          option2: option2,
          option3: option3,
          acl_selected: option1List,
        },
      };

      if (
        this.productDel.variant_options.length == 1 &&
        this.productDel.variant_options[0].option_values.length == 1
      ) {
        // let defaultOption = this.productDel.variant_options[0].option_values[0].option_id;
        // params = {
        //     "option_selected": [option1List.join(','),this.productDel.variant_options[0].option_values[num1].option_value,this.productDel.variant_options[1].option_values[num2].option_value]
        // }
        this.confirmOrder(params);
      } else {
        this.confirmOrder(params);
      }
      this.showPayInfo = false;
    },
    //关闭所有弹窗
    closePay() {
      this.showPayInfo = false;
      this.showPayBox = false;
      this.showPayCode = false;
      this.showLoading = false;
      this.choseAclShow = false;
    },
    getTapList() {
      tapList(
        (res) => {
          this.tapList = res.data.data;
          this.getProductList();
        },
        (err) => {
          console.log(err);
        }
      );
    },
    // 获取产品列表
    getProductList() {
      var that = this;
      productListAll(
        (res) => {
          this.productListData = res.data.data;
          let array = [];
          //
          res.data.data.forEach((ele) => {
            if (ele.product_collections[0].id == this.tapList[0].id) {
              array.push(ele);
            }
          });
          this.proxyList = array;
          const { parId } = this.$route.query;
          if (parId) {
            const index = this.tapList.findIndex((item) => item.id == parId);
            if (index >= 0) this.chooseProductIndex(index);
          }
        },
        (err) => {
          console.log(err);
        }
      );
    },
    // 根据标签获取产品列表
    getLabelProduct(id) {
      labelProduct(
        { id: id },
        (res) => {
          this.productListData.push(res.result[0]);
        },
        (err) => {
          console.log(err);
        }
      );
    },
    // 选择分类
    chooseProductIndex(index) {
      this.chooseIndex = index;
      let array = [];

      this.productListData.forEach((ele) => {
        if (ele.product_collections[0].id == this.tapList[index].id) {
          array.push(ele);
        }
      });
      this.proxyList = array;
      const { id } = this.$route.query;
      if (id) {
        const proxyIndex = this.proxyList.findIndex((item) => item.id == id);
        if (proxyIndex >= 0) this.openShowPayInfo(this.proxyList[proxyIndex]);
      }
    },
    buttonChange(index, sid) {
      let lastName = "";
      let num1, num2, num;

      if (this.productDel.variant_options.length == 1) {
        lastName =
          this.productDel.variant_options[0].option_values[
            this.productDel.variant_options[0].radio
          ].option_value;
      } else if (this.productDel.variant_options.length == 2) {
        num1 = Number(this.productDel.variant_options[0].radio);
        num2 = Number(this.productDel.variant_options[1].radio);
        lastName =
          this.productDel.variant_options[0].option_values[num1].option_value +
          " / " +
          this.productDel.variant_options[1].option_values[num2].option_value;
      } else {
        num = Number(this.productDel.variant_options[2].radio);
        num1 = Number(this.productDel.variant_options[0].radio);
        num2 = Number(this.productDel.variant_options[1].radio);
        lastName =
          this.productDel.variant_options[0].option_values[num1].option_value +
          " / " +
          this.productDel.variant_options[1].option_values[num2].option_value +
          " / " +
          this.productDel.variant_options[2].option_values[num].option_value;
      }

      // 根据当前的选择去查询  不再整理 支付数据参数
      // return
      console.log(lastName);
      // debugger
      let obj = this.productDel.variants.find(
        (ele) => ele.variant_name == lastName
      );
      // let obj = this.productDel.variants.find(ele => ele.variant_name == ('0 / ' + lastName))
      // this.productDel.location = obj.variant_desc
      this.productDel.location = obj ? obj.variant_desc : "";
      this.productNum = obj.cart_step;
      this.is_active = obj.is_active;
      this.productDel.storage = obj.variant_stock;
      this.productDel.cart_step = obj.cart_step;
      this.productDel.variant_price = obj.variant_price;
      this.productDel.variant_option1 = obj.variant_option1;
      this.productDel.variant_option2 = obj.variant_option2;
      this.totalPrice = this.productNum * this.productDel.variant_price;

      if (
        this.productDel.variant_options.length == 1 &&
        this.productDel.variant_options[0].option_values.length == 1
      ) {
        this.productDel.variant_options[0].radio = sid + "";
      }

      this.$forceUpdate();
    },

    // 创建订单
    confirmOrder(params) {
      this.showLoading = true;

      createOrder(
        params,
        (res) => {
          this.showLoading = false;
          this.$message.success("Order generated successfully");
          // this.payCodeImage = res.result.url
          // let newWindow = window.open('', '_blank',"menubar=0,scrollbars=1, resizable=1,status=1,titlebar=0,toolbar=0,location=no");
          // newWindow.location = res.data.checkout_url;
          const { checkout_url } = res.data;
          const a = document.createElement("a");
          a.href = checkout_url;
          a.target = "_blank";
          document.body.appendChild(a);
          a.click();
          a.remove();
          this.dialogVisible = true;
          this.checkOutOrder(res.data.order_id);
        },
        (err) => {
          this.showLoading = false;
        }
      );
    },
    checkOutOrder(orderNumber, timeout = 1800000, interval = 3000) {
      this.showLoading = true;
      const start = Date.now();
      this.intervalId = setInterval(() => {
        this.remainingTime = timeout - (Date.now() - start);

        if (this.remainingTime <= 0) {
          // 超时，停止轮询
          clearInterval(this.intervalId);
        }
        checkOrderStatus(orderNumber)
          .then((result) => {
            if (result === "success") {
              // 已支付，停止轮询
              clearInterval(this.intervalId);
              this.gotoDashboard();
            }
          })
          .catch((error) => {
            this.showLoading = false;
            console.log(error);
          });
      }, interval);
    },
    handleClose(done) {
      this.$confirm("Are you sure to close this page and go to Dashboard?")
        .then((_) => {
          done(); // 调用 done() 函数来关闭对话框
          this.gotoDashboard();
        })
        .catch((_) => {
          // 如果用户取消关闭页面，不要调用 done() 函数
        });
    },

    gotoDashboard() {
      this.$router.push("/dashboard/index/proxies");
      this.dialogVisible = false;
      this.showLoading = false;
    },
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop; // 滚动条偏移量
      this.scrollTop = scrollTop;
    },
    handleDomainDialogClose(done) {
      this.domainListVisible = false;
      done();
    },
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
};
</script>
<style lang="scss" scoped>
.select-acl {
  font-family: "Pang";
  background: #ffffff;
  border-radius: 6px;
  line-height: 40px;
  width: 100%;
  text-align: center;
}
.select-acl:hover {
  background-color: #8dc2f7;
}
.select-acl-selected {
  font-family: "Pang";
  background: #409eff;
  border-radius: 2px;
  line-height: 40px;
  color: #ffffff;
  width: 100%;
  width: 100%;
  text-align: center;
}
.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  width: 140px;
}
.pro-show-ellipsis {
  // overflow: hidden;
  // white-space: nowrap;
  // text-overflow: ellipsis;
}
.option-card {
  .item {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;

    & > div {
      text-align: center;
      width: 130px;
    }
  }
}

.layout {
  background: url(../assets/images/fbj1.jpg) no-repeat center/cover;
}

.floginvia {
  background: url(../assets/images/fimg5.jpg) no-repeat center/cover;
}

.fneonsw_slide {
  background: url(../assets/images/fimg3.jpg) no-repeat center/cover;
  display: block;
  padding: 17px 10px 40px 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 18px;
  overflow: hidden;
}

.fneonsw {
  padding: 30px 0px;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
  height: 420px;
  overflow-y: scroll;
  .fneonsw_slide {
    width: 320px;
    margin-bottom: 20px;
    margin: 0px 1% 20px 1%;
    // height: 130px;
    h2 {
      font-size: 26px;
      line-height: 32px;
      color: #404040;
      font-family: "Pang";
      transition: all 0.3s;
    }
    p {
      margin-top: 6px;
      font-size: 20px;
      line-height: 1.5;
      color: #666666;
      height: 7.5em;
      overflow: hidden;
    }
  }
  //整个滚动条宽度
  &::-webkit-scrollbar {
    width: 2px;
  }
  //轨道背景
  &::-webkit-scrollbar-track {
    border-radius: 5px;
    // background: #F3FAFF;
    opacity: 0.5;
  }
  //滚条
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #e274aa;
  }
}

.el-input-number {
  width: 136px !important;
}

.clearfix li {
  line-height: 24px;
  font-size: 16px;
  cursor: pointer;
}

.on {
  border-bottom: 2px solid #e274aa;
}

.grey {
  background: grey !important;
  cursor: not-allowed;
}

.remaining-time {
  font-size: 18px;
  text-align: center;
  margin-bottom: 20px;
}

.timeout {
  font-size: 18px;
  text-align: center;
  margin-bottom: 20px;
  color: #f56c6c;
}

.goto-dashboard {
  margin-left: 140px;
}

.domain-dialog {
  :deep(.el-dialog) {
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
    backdrop-filter: blur(8px);
    border: 1px solid rgba(255, 255, 255, 0.1);
    max-width: 90vw;
    margin: 1.5rem auto;
    
    @media (min-width: 640px) {
      max-width: 460px;
    }

    .el-dialog__header {
      padding: 0;
      margin: 0;
    }

    .el-dialog__body {
      padding: 0;
    }

    .el-dialog__headerbtn {
      display: none !important;
      z-index: -1;
      opacity: 0;
      visibility: hidden;
    }
  }
}

.domain-list-container {
  .domain-header {
    padding: 24px 28px;
    background: linear-gradient(135deg, #3b82f6 0%, #6366f1 100%);
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      top: -50%;
      left: -50%;
      width: 200%;
      height: 200%;
      background: radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 60%);
      animation: pulse 15s infinite;
    }

    .title-section {
      display: flex;
      align-items: center;
      position: relative;
      z-index: 1;
      
      i {
        font-size: 26px;
        margin-right: 14px;
        opacity: 0.9;
      }

      .main-title {
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 0.3px;
      }
    }

    .close-btn {
      cursor: pointer;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      background: rgba(255, 255, 255, 0.15);
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
      position: relative;
      z-index: 1;
      backdrop-filter: blur(4px);

      i {
        font-size: 20px;
        color: white;
        opacity: 0.9;
        transition: all 0.3s ease;
      }

      &:hover {
        background: rgba(255, 255, 255, 0.25);
        transform: scale(1.05);

        i {
          opacity: 1;
          transform: rotate(90deg);
        }
      }
    }
  }

  .domain-list {
    max-height: 400px;
    overflow-y: auto;
    padding: 16px 0;
    background: #fff;
    
    &::-webkit-scrollbar {
      width: 6px;
    }
    
    &::-webkit-scrollbar-track {
      background: #f1f5f9;
    }
    
    &::-webkit-scrollbar-thumb {
      background: #cbd5e1;
      border-radius: 6px;
      
      &:hover {
        background: #94a3b8;
      }
    }
    
    .domain-item {
      padding: 16px 28px;
      display: flex;
      align-items: center;
      transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
      border-left: 3px solid transparent;
      
      i {
        font-size: 18px;
        color: #3b82f6;
        margin-right: 14px;
        transition: all 0.2s ease;
      }
      
      .domain-text {
        font-size: 15px;
        color: #334155;
        font-family: 'SF Mono', 'Monaco', monospace;
        letter-spacing: -0.3px;
      }
      
      &:hover {
        background-color: #f8fafc;
        border-left-color: #3b82f6;

        i {
          transform: translateX(2px);
          color: #2563eb;
        }
      }
    }
    
    .no-domains {
      padding: 40px 28px;
      text-align: center;
      color: #94a3b8;
      display: flex;
      flex-direction: column;
      align-items: center;
      
      i {
        font-size: 32px;
        margin-bottom: 12px;
        opacity: 0.7;
      }
      
      span {
        font-size: 15px;
        line-height: 1.6;
      }
    }
  }
}

.view-domains-btn {
  cursor: pointer;
  color: #3b82f6;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  font-weight: 500;
  
  &:hover {
    color: #2563eb;
    text-decoration: underline;
    text-underline-offset: 2px;
  }
}

@keyframes pulse {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
